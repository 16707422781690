import {
  CopyOutlined,
  QuestionCircleFilled,
  ShareAltOutlined,
} from "@ant-design/icons"
import { useQuery } from "@tanstack/react-query"
import { useState } from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import { useTranslation } from "react-i18next"
import Aside from "../../components/Aside"
import { CreateMatchCard } from "../../components/Match/ButtonCreateMatch"
import { ProductCard } from "../../components/Product/ProductCard"
import CardProductSkeleton from "../../components/Skeloton"
import { useUSer } from "../../hook/useUser"
import { GetProductsByAccount } from "../../services/request/Product"
import type { TProduct } from "../../types"
import { GeneralResume } from "../../views/GeneralResume/GeneralResume"
import { MatchesPanel } from "../../views/MatchesPanel/MatchesPanel"
import { message, Tooltip } from "antd"
import { RightSquareOutlined } from "../../components/Icons"
import { MatchModalUpdate } from "../../views/MatchModalUpdate"
import { Helmet } from "react-helmet"
import { useLocation } from "react-router-dom"

const Resume = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const { data: user } = useUSer()
  const [editProduct, setEditProduct] = useState<TProduct>()
  const [currentProduct, setCurrentProduct] = useState<TProduct>()
  const [openAside, setOpenAside] = useState<boolean>(false)
  const [messageAlert, contextHolder] = message.useMessage()

  const {
    data: products,
    isLoading: isLoadingProducts,
    refetch: refetchProducts,
  } = useQuery({
    queryKey: ["getProducts"],
    queryFn: () => GetProductsByAccount(user!.id),
  })
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Helmet.resume.title")}</title>
        <meta name="description" content={t("Helmet.resume.description")} />
        <link rel="canonical" href={origin + location.pathname} />
      </Helmet>
      {contextHolder}
      <MatchModalUpdate
        open={!!editProduct}
        edit={editProduct!}
        refresh={() => refetchProducts()}
        handleClose={() => setEditProduct(undefined)}
      />
      <div className="flex flex-row justify-between overflow-hidden">
        <div className="flex flex-1 flex-col overflow-hidden pr-6">
          <GeneralResume />
          <div className="mt-8 flex flex-col gap-y-8">
            <MatchesPanel
              title={
                <div className="flex items-center gap-x-2">
                  <p className="m-0 flex items-center gap-x-4 text-[1.625rem] font-bold">
                    <RightSquareOutlined />
                    {t("Resume.Products.title")}
                  </p>
                  <Tooltip
                    title={t("Resume.Products.tooltip")}
                    className="text-xl"
                  >
                    <QuestionCircleFilled />
                  </Tooltip>
                </div>
              }
            >
              <div className="flex flex-wrap gap-6">
                {isLoadingProducts ? (
                  Array.from({ length: 4 }).map((_, index) => (
                    <CardProductSkeleton key={index} />
                  ))
                ) : products && products.length > 0 ? (
                  products.map((product) => (
                    <ProductCard
                      product={product}
                      key={product.id}
                      setEdit={setEditProduct}
                      existsSellableProduct={products.some(
                        (p) =>
                          p.external_id === product.external_id &&
                          p.enable_to_sell === true,
                      )}
                      execute={() => {
                        if (currentProduct?.id === product.id) {
                          setOpenAside(!openAside)
                          setCurrentProduct(undefined)
                        } else if (currentProduct?.id) {
                          setCurrentProduct(product)
                        } else {
                          setOpenAside(!openAside)
                          setCurrentProduct(product)
                        }
                      }}
                    />
                  ))
                ) : (
                  <CreateMatchCard refetch={() => refetchProducts()} />
                )}
              </div>
            </MatchesPanel>

            <div className="h-48 bg-secondary p-8">
              <div className="flex items-center gap-x-4">
                <ShareAltOutlined className="text-3xl text-gray-500" />
                <h3 className="m-0 text-2xl font-bold">
                  {t("Profile.PublicProfile.title")}
                </h3>
              </div>
              <p className="mb-0 mt-6 text-neutral">
                {t("Profile.ShareLinkOtherPlayers")}
              </p>
              <div className="mt-6 flex h-10 justify-between gap-x-8">
                <span className="flex w-full items-center border border-solid border-[#262626] bg-primary px-4 font-mono text-gray-500">
                  {origin}/{user?.name}
                </span>
                <CopyToClipboard
                  text={`${origin}/${user?.name}`}
                  onCopy={() => messageAlert.success(t("Profile.LinkCopied"))}
                >
                  <button className="flex h-full px-2 cursor-pointer items-center justify-center rounded-sm bg-[#FF7A00] font-semibold uppercase">
                    <span className="max-sm:hidden">
                      {t("Profile.CopySaleLink")}
                    </span>
                    <CopyOutlined className="sm:hidden text-2xl" />
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
        <Aside
          product={currentProduct}
          open={openAside}
          setOpen={setOpenAside}
        />
      </div>
    </>
  )
}

export default Resume
