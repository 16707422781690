export function CloseOutlinedIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={52}
      height={52}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={52} height={52} rx={8} fill="#1B0200" />
      <path
        d="M27.619 26l8.203-9.778a.249.249 0 00-.191-.41h-2.494a.51.51 0 00-.384.179l-6.766 8.065-6.765-8.065a.5.5 0 00-.385-.178h-2.494a.249.249 0 00-.19.409L24.356 26l-8.203 9.778a.248.248 0 00.056.37c.04.026.087.04.134.04h2.494a.509.509 0 00.385-.179l6.765-8.065 6.766 8.065a.5.5 0 00.384.178h2.494a.249.249 0 00.19-.409L27.62 26z"
        fill="#F50C1A"
      />
    </svg>
  )
}
