import {
  EyeInvisibleOutlined,
  EyeOutlined,
  QuestionCircleFilled,
} from "@ant-design/icons"
import { Button, message, Tooltip } from "antd"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useUSer } from "../../hook/useUser"
import CopyToClipboard from "react-copy-to-clipboard"
import { useQuery } from "@tanstack/react-query"
import { PostObsVerify } from "../../services/request/ObsVerify"

export function CopyObsLink() {
  const [messageAlert, contextHolder] = message.useMessage()
  const { data: user } = useUSer()
  const { t } = useTranslation()
  const [seeLink, setSeeLink] = useState(false)
  const OBS_LINK = window.location.host + "/obs/" + user?.name

  const { refetch: handleVerify, isFetching } = useQuery({
    queryKey: ["obs-verify"],
    queryFn: () => PostObsVerify({ account_id: user!.id }),
    enabled: false,
  })
  return (
    <>
      {contextHolder}
      <div className="flex flex-col">
        <h2 className="m-0 mt-8 flex gap-x-2 text-base font-semibold uppercase text-white">
          {t("Profile.OBS.title")}
          <Tooltip title={t("Profile.OBS.tooltip")}>
            <QuestionCircleFilled />
          </Tooltip>
        </h2>
        <p className="mb-0 mt-4 text-description">{t("Profile.OBS.uselink")}</p>
        <div className="mt-6 flex h-10 justify-between">
          <div className="flex w-full items-center gap-4 text-description">
            <div className="flex h-full w-full items-center justify-between border border-solid border-[#262626] bg-secondary pl-2 font-mono text-xs">
              {seeLink
                ? OBS_LINK
                : Array.from(OBS_LINK)
                    .map(() => "*")
                    .join("")}
              <Button
                className="flex h-full cursor-pointer items-center justify-center bg-secondary text-white"
                onClick={() => setSeeLink(!seeLink)}
              >
                {seeLink ? <EyeOutlined /> : <EyeInvisibleOutlined />}
              </Button>
            </div>
            <CopyToClipboard
              text={OBS_LINK}
              onCopy={() => messageAlert.success(t("Profile.LinkCopied"))}
            >
              <Button className="h-full rounded-sm bg-orange-500 font-bold text-black">
                {t("Profile.Copy")}
              </Button>
            </CopyToClipboard>
              <Button
                loading={isFetching}
                disabled={isFetching}
                onClick={() => handleVerify()}
                className="h-full rounded-sm bg-orange-500 font-bold text-black"
              >
                {t("Profile.OBS.test")}
              </Button>
          </div>
        </div>
      </div>
    </>
  )
}
