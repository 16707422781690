import { ReactNode } from "react"
import { Section } from "../../components/Section/Section"

type MatchesPanelProps = {
  title: string | ReactNode
  children: ReactNode
}
export const MatchesPanel = ({ title, children }: MatchesPanelProps) => {
  return (
    <Section>
      <div className="mb-4 p-0 text-sm font-bold text-white md:mb-6 md:text-base">
        {title}
      </div>
      {children}
    </Section>
  )
}
