import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore"
import { db } from "../../services/firebase/db"
import { useEffect, useRef, useState } from "react"
import { Speak } from "./start-speak"
import { useTalent } from "../../hook/useTalent"
import { ObsMessage } from "./obsMessage"
import { PatchObsVerify } from "../../services/request/ObsVerify"

export function ObsVerify() {
  const { data: talent } = useTalent()
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [active, setActive] = useState<boolean>()

  useEffect(() => {
    const q = query(
      collection(db, "obs-verify"),
      where("account_id", "==", talent?.id),
      orderBy("createdAt", "desc"),
      limit(1),
    )

    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if (doc.data().showed === false) {
          Speak({
            client_name: "fulano",
            talent_name: talent?.name || "",
            category: "VALORANT",
          })
          setActive(true)
          setTimeout(() => {
            setActive(false)
          }, 10000)
          PatchObsVerify({ id: doc.id })
        }
      })
    })
  }, [talent?.id, talent?.name])

  useEffect(() => {
    if(buttonRef.current) {
      buttonRef.current.click();
    }
  }, [])

  return (
    <>
      <button ref={buttonRef} className="hidden"/>
      <ObsMessage
        active={active}
        client_name="fulano"
        talent_name={talent!.name}
        category="VALORANT"
      />
    </>
    
  )
}