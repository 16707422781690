import { Spin } from "antd"
import { ReactNode } from "react"

type props = {
  isLoading: boolean
  children: ReactNode
}
export function ErrorScreen({ children, isLoading }: props) {
  return (
    <div className="flex h-screen w-full items-center justify-center bg-black text-6xl uppercase text-white">
      {isLoading ? <Spin size="large" /> : children}
    </div>
  )
}
