import { Divider } from "antd"
import clsx from "clsx"
import { ReactNode } from "react"

type Props = {
  Icon: ReactNode
  Title: ReactNode
  On: boolean
}
export default function StepHeader({ Icon, Title, On }: Props) {
  return (
    <div className="flex h-full w-1/2 flex-col items-center justify-between">
      <div className="relative flex w-full items-center justify-center">
        <Divider
          className={clsx(
            "absolute m-0 h-1 rounded-md transition-all duration-1000",
            On ? "bg-orange-500" : "bg-neutral",
          )}
        />
        <div
          className={clsx(
            "z-10 flex h-12 w-12 items-center justify-center rounded-full transition-all duration-1000",
            On ? "bg-orange-500" : "bg-neutral",
          )}
        >
          {Icon}
        </div>
      </div>
      <span className="font-bold uppercase">{Title}</span>
    </div>
  )
}
