import { Navigate, useLocation } from "react-router-dom"
import { useAuthContext } from "../context/auth"

type Props = {
  Component: JSX.Element
}

export function PrivateRoute({ Component }: Props) {
  const { isLoggedIn } = useAuthContext()
  const location = useLocation()
  const from = location.pathname + location.search
  return isLoggedIn ? (
    Component
  ) : (
    <>
      {localStorage.setItem("@DuoToPlay:history", from)}
      <Navigate to="/login" />
    </>
  )
}
