import MenuIcon from "@mui/icons-material/Menu"
import MenuOpenIcon from "@mui/icons-material/MenuOpen"
import { Button, Layout } from "antd"
import { ChangeLang } from "../../ChangeTheme/ChangeTheme"
import { Link } from "react-router-dom"
import { GetLinkToConnectStripe } from "../../../services/request/Account"
import { useUSer } from "../../../hook/useUser"
import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { clsx } from "clsx"

const { Header } = Layout

type HeaderViewProps = {
  collapsed: boolean
  setCollapsed: (collapsed: boolean) => void
}

export const HeaderView = (props: HeaderViewProps) => {
  const { data: user } = useUSer()
  const { t } = useTranslation()

  const { refetch, isLoading } = useQuery({
    queryKey: ["StripeConnect"],
    queryFn: async () => {
      const { link_to_connect } = await GetLinkToConnectStripe()
      window.location.href = link_to_connect
    },
    enabled: false,
  })
  const stripeCheckingAccount = () => {
    return user?.stripe_pending_requirements?.includes(
      "individual.political_exposure",
    )
  }
  return (
    <Header className="flex h-[3.75rem] items-center justify-between border-0 border-solid p-0 text-xl tracking-widest">
      <div className="m-0 flex w-80 justify-center gap-x-2">
        <Button
          className="m-0 ml-2 min-w-0 p-0 lg:hidden"
          onClick={() => props.setCollapsed(!props.collapsed)}
        >
          {!props.collapsed ? <MenuOpenIcon /> : <MenuIcon />}
        </Button>
        <Link
          to="/portal/resume"
          className="text-xl font-bold tracking-widest text-white"
        >
          <p className="m-0 flex text-center max-sm:hidden">DUOTOPLAY</p>
          <p className="m-0 flex text-center sm:hidden">DUO</p>
        </Link>
      </div>
      <div className="mr-8 flex flex-row items-center justify-center gap-x-4">
        {!user?.isTalent && (
          <Button
            className={clsx(
              "h-8 animate-pulse border-none font-bold uppercase text-black hover:scale-105 max-sm:px-2",
              stripeCheckingAccount() ? "bg-warning" : "bg-success",
            )}
            onClick={() => {
              refetch()
            }}
            loading={isLoading}
          >
            <p className="m-0 max-sm:text-xs">
              {!user?.isTalent && !user?.stripe_pending_requirements
                ? t("Header.stripe.becomeATalent")
                : stripeCheckingAccount()
                  ? t("Header.stripe.weAreChecking")
                  : t("Header.stripe.weNeedMoreInfo")}
            </p>
          </Button>
        )}
        <ChangeLang />
      </div>
    </Header>
  )
}
