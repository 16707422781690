import { Divider, Skeleton } from "antd"

export default function CardProductSkeleton() {
  return (
    <div className="h-[26rem] w-[15.875rem] rounded-sm bg-tertiary">
      <div className="h-8 w-full"></div>
      <Skeleton.Image active className="h-64 w-full rounded-sm bg-tertiary" />
      <div className="mb-4 ml-4 mt-4 h-4 w-[70%] animate-pulse rounded-full bg-description"></div>
      <div className="ml-2 mt-2 h-2.5 w-[80%] animate-pulse rounded-full bg-description"></div>
      <Divider className="my-4" />
      <div className="m-auto h-3 w-[30%] animate-pulse rounded-full bg-description"></div>
    </div>
  )
}
