import { Modal as AntModal, Flex, Typography } from "antd"
import { duoStyle } from "../../../styles/duoStyle"
import { CloseOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"
import { ReactNode } from "react"

type ModalProps = {
  title: ReactNode
  open: boolean
  children: React.ReactNode
  handleClose(): void
}

export const Modal = (props: ModalProps) => {
  const { t } = useTranslation()
  const { open, title, children, handleClose } = props

  return (
    <>
      <AntModal
        open={open}
        onCancel={handleClose}
        footer={null}
        closable={false}
        maskClosable={false}
        styles={{
          content: {
            padding: 24,
            backgroundColor: "#1B1B1B",
            width: 600,
          },
        }}
      >
        <Flex
          justify="space-between"
          align="center"
          style={{ marginBottom: 2 }}
        >
          <Typography.Title
            level={4}
            style={{ padding: 0, margin: 0, color: "#fff" }}
          >
            {title}
          </Typography.Title>
          <Typography.Text
            style={{
              padding: 0,
              cursor: "pointer",
              fontWeight: "bold",
              textTransform: "uppercase",
              fontSize: 14,
              color: duoStyle.primary.main,
            }}
            onClick={handleClose}
          >
            {t("modal.close")}
            <CloseOutlined style={{ paddingLeft: 5 }} />
          </Typography.Text>
        </Flex>
        {children}
      </AntModal>
    </>
  )
}
