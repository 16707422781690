import { AliasToken } from "antd/es/theme/interface"
import { duoStyle } from "./duoStyle"
import { ThemeConfig, theme } from "antd"

const { darkAlgorithm } = theme

export const antdToken: Partial<AliasToken> = {
  colorBgBase: duoStyle.background.default,
  colorPrimaryBg: duoStyle.background.default,
  colorPrimary: duoStyle.primary.main,
}

export const antdTheme: Partial<ThemeConfig> = {
  components: {
    Layout: {
      bodyBg: duoStyle.background.default,
      headerBg: duoStyle.background.secondary,
      siderBg: duoStyle.background.secondary,
      headerHeight: 52,
    },
    Menu: {
      activeBarBorderWidth: 0,
      colorText: duoStyle.text.secondary,
      itemSelectedBg: duoStyle.background.default,
      itemBg: duoStyle.background.secondary,
      colorBorder: duoStyle.background.default,
      itemSelectedColor: duoStyle.text.primary,
      itemBorderRadius: 5,
    },
    Modal: {
      contentBg: duoStyle.background.default,
    },
  },
  token: antdToken,
  algorithm: [darkAlgorithm],
}
