import { Skeleton } from "antd"

type Props = {
  alt?: string
  src?: string
}
export function UserPicture({ alt, src }: Props) {
  return alt && src ? (
    <img
      alt={alt}
      width={200}
      height={200}
      className="h-[200px] w-[200px] animate-fade-in rounded-[4px]"
      src={src}
    />
  ) : (
    <Skeleton.Image
      active
      className="h-[200px] w-[200px] rounded-[4px] bg-tertiary"
    />
  )
}
