import {
  BrowserRouter,
  Navigate,
  Route,
  Routes as Switch,
} from "react-router-dom"
import { CallbackLoginPage } from "../pages/login/CallbackLoginPage"
import { Login } from "../pages/login/LoginPage"
import { PrivateRoute } from "./PrivateRoute"
import MatchesPage from "../pages/matches"
import ObsPage from "../pages/obs"
import { Completion } from "../pages/payment/CompletionPage"
import { Payment } from "../pages/payment/PaymentPage"
import Resume from "../pages/resume"
import { TalentPage } from "../pages/talent"
import {
  LayoutPayment,
  LayoutView,
  LayoutViewSecondary,
} from "../components/layout"
import Profile from "../pages/profile"
import Terms from "../pages/terms"
import FAQ from "../pages/faq"
import { Talents } from "../pages/talents"

export function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/login" Component={Login} />
        <Route path="/obs/:talent_nickname" Component={ObsPage} />
        <Route
          path="/auth/callback/:identifier"
          Component={CallbackLoginPage}
        />
        <Route
          path="/terms"
          element={
            <LayoutViewSecondary>
              <Terms />
            </LayoutViewSecondary>
          }
        />
        <Route
          path="/faq"
          element={
            <LayoutViewSecondary>
              <FAQ />
            </LayoutViewSecondary>
          }
        />
        <Route
          path="/talents/all"
          element={
            <LayoutViewSecondary>
              <Talents />
            </LayoutViewSecondary>
          }
        />
        <Route
          path="/payment"
          element={
            <PrivateRoute
              Component={
                <LayoutPayment>
                  <Payment />
                </LayoutPayment>
              }
            />
          }
        />
        <Route
          path="/completion"
          element={
            <PrivateRoute
              Component={
                <LayoutPayment>
                  <Completion />
                </LayoutPayment>
              }
            />
          }
        />
        <Route path="/portal">
          <Route
            path="/portal"
            element={<Navigate to="/portal/resume" replace />}
          />
          <Route
            path="resume"
            element={
              <PrivateRoute
                Component={
                  <LayoutView onSidebar>
                    <Resume />
                  </LayoutView>
                }
              />
            }
          />
          <Route
            path="report"
            element={
              <PrivateRoute
                Component={
                  <LayoutView onSidebar>
                    <p>report</p>
                  </LayoutView>
                }
              />
            }
          />
          <Route
            path="matches"
            element={
              <PrivateRoute
                Component={
                  <LayoutView onSidebar>
                    <MatchesPage />
                  </LayoutView>
                }
              />
            }
          />
          <Route
            path="profile"
            element={
              <PrivateRoute
                Component={
                  <LayoutView onSidebar>
                    <Profile />
                  </LayoutView>
                }
              />
            }
          />
        </Route>
        <Route
          path="/:talent_nickname"
          element={
            <LayoutViewSecondary>
              <TalentPage />
            </LayoutViewSecondary>
          }
        />
      </Switch>
    </BrowserRouter>
  )
}
