import { Skeleton, Tooltip } from "antd"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import {
  DollarOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  LineChartOutlined,
  QuestionCircleFilled,
  ShoppingCartOutlined,
} from "@ant-design/icons"
import { useQuery } from "@tanstack/react-query"
import { ResumeCard } from "../../components/ResumeCard/ResumeCard"
import { Section } from "../../components/Section/Section"
import { GetAccountPerformance } from "../../services/request/Account"
import { FormatCurrency } from "../../utils/currencyHelper"
import { env } from "../../utils/env"
import { useUSer } from "../../hook/useUser"

export const GeneralResume = () => {
  const { t } = useTranslation()
  const { data: user } = useUSer()  
  const [showBalance, setShowBalance] = useState(false)

  const { data: perform } = useQuery({
    queryKey: ["getAccountPerformance"],
    queryFn: () => GetAccountPerformance(),
    enabled: !!user?.isTalent,
  })

  return (
    <Section>
      <div className="mb-4 flex items-center justify-between">
        <div className="flex items-center gap-x-2">
          <h1 className="m-0 flex items-center justify-center gap-x-4 text-[1.625rem] font-bold text-white">
            <LineChartOutlined className="text-neutral" />
            {t("PerformanceDay.title")}
          </h1>
          <Tooltip title={t("PerformanceDay.tooltip")}>
            <QuestionCircleFilled className="text-xl text-white" />
          </Tooltip>
        </div>
        <div>
          <a
            href={env.STRIPE_WALLET_URL}
            className="cursor-pointer font-bold uppercase text-orange-500 hover:text-orange-300"
            target="_blank"
            rel="noreferrer"
          >
            {t("goToWallet.title")}
          </a>
          <Tooltip
            title={t("goToWallet.tooltip", { contact: env.DUO_EMAIL_CONTACT })}
          >
            <QuestionCircleFilled className="ml-2 text-base text-white" />
          </Tooltip>
        </div>
      </div>
      <div className="flex w-full flex-col items-stretch justify-between gap-8 xxl:flex-row">
        <ResumeCard
          title={t("Balance")}
          icon={
            <DollarOutlined className="h-[3.625rem] w-[3.625rem] justify-center rounded-lg bg-[#0E2204] text-[1.625rem]" />
          }
        >
          <div className="flex items-center gap-x-4">
            
              <>
                <div className="m-0 flex h-5 items-center text-xl">
                  {showBalance ? (
                    FormatCurrency({
                      value: perform?.amount || 0,
                      currency: perform?.currency || "BRL",
                    })
                  ) : (
                    <Skeleton.Input className="m-0 mr-1 h-[1.4rem] w-[60px] min-w-[60px] p-0" />
                  )}
                </div>
                <div
                  className="flex cursor-pointer items-center"
                  onClick={() => setShowBalance(!showBalance)}
                >
                  {showBalance ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                </div>
              </>
          </div>
        </ResumeCard>
        <ResumeCard
          title={t("Resume.matchesPerformed")}
          icon={
            <ShoppingCartOutlined className="h-[3.625rem] w-[3.625rem] justify-center rounded-lg bg-[#072121] text-[1.625rem]" />
          }
        >
          {perform?.matches_played || 0}
        </ResumeCard>
      </div>
    </Section>
  )
}
