import { t } from "i18next"
import { TProduct } from "../../types"
import { FormatCurrency } from "../../utils/currencyHelper"
import { formatImage } from "../../utils/imageHelper"

type ProductCardProps = {
  onClick: () => void
  product: TProduct
}

const AVATAR_IMAGE_SIZE = {
  width: 254,
  height: 246,
}

export const ProductPlayedCard = ({ product, onClick }: ProductCardProps) => {
  return (
    <div className="relative h-[28rem] w-[15.875rem] flex-shrink-0 bg-tertiary">
      <div className="flex-shrink-0 rounded-sm border-success shadow transition-all duration-200 hover:border-2">
        <p
          className={`m-0 flex h-[1.875rem] w-full items-center justify-center py-1 text-center text-base text-sm font-semibold grayscale`}
        >
          {product.amount}/{product.amount_offered || product.amount}{" "}
          {t("card.productAmountPlaces")}
        </p>

        <img
          src={formatImage(
            product.avatar,
            AVATAR_IMAGE_SIZE.width,
            AVATAR_IMAGE_SIZE.height,
          )}
          alt={product.name}
          className="w-full object-cover grayscale"
        />

        <div className="px-4 py-4">
          <p className="m-0 truncate p-0 text-base font-semibold text-description">
            {product.title}
          </p>
          <p className="m-0 h-16 overflow-hidden text-ellipsis p-0 text-sm text-gray-500">
            {product.description}
          </p>
        </div>

        <div className="flex h-10 w-full flex-col items-center justify-between">
          <div className="flex h-[1px] w-[95%] bg-gray-600 opacity-30"></div>
          <div className="flex w-full items-center justify-between px-8">
            <span className="text-center text-xs uppercase">
              {product.device}
            </span>
            <span className="bold m-0 p-0 text-xl font-semibold text-gray-400">
              {FormatCurrency({
                value: product.value,
                currency: product.currency,
              })}
            </span>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 flex h-full w-full items-center justify-center bg-black bg-opacity-80 opacity-0 transition-all duration-500 hover:opacity-100">
        <button
          className="h-12 w-40 cursor-pointer rounded border-none bg-[#FF7A00] text-sm font-bold uppercase"
          onClick={onClick}
        >
          {t("ReactivateMatch")}
        </button>
      </div>
    </div>
  )
}
