import { useContext, useEffect } from "react"
import { useLocation, useParams, useNavigate } from "react-router-dom"
import { AuthContext } from "../../context/auth"
import { Spin } from "antd"

export function CallbackLoginPage() {
  const { signIn, isLoggedIn } = useContext(AuthContext)
  const page_history = localStorage.getItem("@DuoToPlay:history")

  const { identifier } = useParams()
  const { search } = useLocation()

  const params = new URLSearchParams(search)
  const code = params.get("code")

  const navegation = useNavigate()

  useEffect(() => {
    if (identifier && code) {
      signIn(identifier, code)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoggedIn) {
    navegation(page_history || "/portal")
    localStorage.removeItem("@DuoToPlay:history")
  }
  return (
    <div className="flex h-screen w-full items-center justify-center bg-secondary">
      <Spin size="large" />
    </div>
  )
}
