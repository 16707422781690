import { StripeElementsOptions } from "@stripe/stripe-js"

export const stripeCheckoutStyles: StripeElementsOptions = {
  mode: "payment",
  appearance: {
    theme: "night",
    // labels: 'floating',

    variables: {
      fontFamily: "Inter, sans-serif",
      colorBackground: "#0F0F10",
      borderRadius: "2px",
      colorTextPlaceholder: "#7B7B7B",
      spacingUnit: "2px",
      fontSizeBase: "14px",
      gridRowSpacing: "1rem",
      gridColumnSpacing: "2rem",
      colorPrimary: "#FF7A00",
      fontWeightNormal: "600",
      colorDanger: "#E8303D",
      colorWarning: "#FFB800",
    },
  },
}
