import { Layout } from "antd"
import { ChangeLang } from "../../ChangeTheme/ChangeTheme"
import { useTranslation } from "react-i18next"
import { PadlockIcon } from "../../Icons"
import { Link } from "react-router-dom"

const { Header } = Layout

export const HeaderView = () => {
  const { t } = useTranslation()
  return (
    <Header className="flex h-[3.75rem] items-center justify-between border-0 border-solid p-0 text-xl">
      <div className="m-0 flex w-80 justify-center">
        <Link
          to="/portal/resume"
          className="text-center text-xl font-bold tracking-widest text-white sm:w-[200px]"
        >
          DUOTOPLAY
        </Link>
      </div>
      <div className="mr-8 flex gap-x-10">
        <span className="flex items-center gap-x-4 text-sm font-bold uppercase">
          <PadlockIcon />
          {t("SecureCheckOut")}
        </span>
        <ChangeLang />
      </div>
    </Header>
  )
}
