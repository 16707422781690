import { useQuery } from "@tanstack/react-query"
import { GetAccountByNickname } from "../services/request/Account"
import { useAuthContext } from "../context/auth"

export function useUSer() {
  const { user } = useAuthContext()

  return useQuery({
    queryKey: ["user"],
    queryFn: async () => {
      if (user) {
        const User = await GetAccountByNickname(user.name)
        return User
      }
      return undefined
    },
  })
}
