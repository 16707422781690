import { ReactNode } from "react"

type ResumeCardProps = {
  title: string
  children?: ReactNode
  icon: ReactNode
}

export const ResumeCard = (props: ResumeCardProps) => {
  const { title, children, icon } = props
  return (
    <div className="items-strech flex h-32 w-full items-center justify-center rounded-[2px] border-border bg-[#1B1B1B]">
      <div className="items-strech w m-0 ml-8 flex h-[3.625rem] grow gap-x-4">
        {icon}
        <div className="flex flex-col justify-center gap-y-2">
          <h2 className="m-0 text-sm text-neutral">{title}</h2>
          <div className="m-0 text-xl font-bold text-white">{children}</div>
        </div>
      </div>
    </div>
  )
}
