import { useTranslation } from "react-i18next"
import { TProduct } from "../../types"
import { formatImage } from "../../utils/imageHelper"
import { Dispatch, SetStateAction, useState } from "react"
import { EditOutlined } from "@ant-design/icons"
import { FormatCurrency } from "../../utils/currencyHelper"
import { clsx } from "clsx"
import { Popconfirm, Switch } from "antd"
import { useMutation } from "@tanstack/react-query"
import { PutProductEnableToSell } from "../../services/request/Product"
import { queryClient } from "../../lib/reactQuery"

interface ActiveCardProps {
  product: TProduct
  setEdit?: Dispatch<SetStateAction<TProduct | undefined>>
  execute: () => void
  existsSellableProduct?: boolean
}

const AVATAR_IMAGE_SIZE = {
  width: 254,
  height: 202,
}

export const ProductCard = ({
  product,
  setEdit,
  execute,
  existsSellableProduct,
}: ActiveCardProps) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const { mutateAsync, isPending } = useMutation({
    mutationFn: PutProductEnableToSell,
    onSuccess: () => {
      const cached = queryClient.getQueryData<TProduct[]>(["getProducts"])
      queryClient.setQueryData<TProduct[]>(
        ["getProducts"],
        cached ? toggleEnableToSell(cached, product) : [],
      )
    },
  })
  const toggleEnableToSell = (
    products: TProduct[],
    targetProduct: TProduct,
  ): TProduct[] => {
    return products.map((product) => {
      if (product.id === targetProduct.id) {
        return {
          ...product,
          enable_to_sell: !product.enable_to_sell,
        }
      } else if (product.external_id === targetProduct.external_id) {
        return {
          ...product,
          enable_to_sell: false,
        }
      }
      return product
    })
  }
  const handleEnableProduct = () => {
    mutateAsync({ id: product.id!, enable_to_sell: !product.enable_to_sell })
  }
  const handleOpenChange = (newOpen: boolean) => {
    if (!newOpen) {
      setOpen(newOpen)
      return
    }
    if (!existsSellableProduct || product.enable_to_sell) {
      handleEnableProduct()
    } else {
      setOpen(newOpen)
    }
  }

  return (
    <div
      className={clsx(
        "relative h-[26rem] flex w-[15.875rem] flex-shrink-0 cursor-pointer flex-col justify-between rounded-sm bg-tertiary shadow transition-all duration-200 hover:scale-105",
        product.online && "border-2 border-solid border-success",
      )}
    >
      <div
        className="relative flex h-[12.625rem] w-full flex-col"
        onClick={execute}
      >
        {product.online && (
          <div className="z-10 flex h-[1.875rem] items-center justify-center bg-bgSuccess text-center">
            <span className="text-base font-semibold text-success">
              {t("CurrentSale")}
            </span>
          </div>
        )}
        <img
          height={AVATAR_IMAGE_SIZE.height}
          width={AVATAR_IMAGE_SIZE.width}
          src={formatImage(
            product.avatar,
            AVATAR_IMAGE_SIZE.width,
            AVATAR_IMAGE_SIZE.height,
          )}
          alt={product.name}
          className={clsx(
            "w-full animate-fade-in rounded-t-sm object-cover",
            !product.online && "grayscale",
          )}
        />
      </div>
      <div className="h-[13rem] px-4 py-4" onClick={execute}>
        <h3 className="m-0 mb-2 truncate text-base font-semibold text-description">
          {product.title} {product.device && "| " + product.device}
        </h3>
        <p className="m-0 mb-2 break-words text-sm text-description">
          {product.description}
        </p>
        <div className="flex items-center justify-between">
          <div
            onClick={execute}
            className={clsx(
              "inline-flex h-8 items-center rounded-sm border border-solid px-2 text-sm",
              product.online
                ? "border-success bg-bgSuccess text-success"
                : "border-neutral bg-[#262626] text-neutral",
            )}
          >
            {product.amount > 0
              ? `${product.amount} ${t("AvailableVacancies")}`
              : t("SoldOff")}
          </div>
          <Popconfirm
            title={t("Product.Confirmation.Title")}
            description={t("Product.Confirmation.Description")}
            onOpenChange={handleOpenChange}
            placement="topRight"
            onConfirm={handleEnableProduct}
            open={open}
            okText={t("Product.Confirmation.Ok")}
            cancelText={t("Product.Confirmation.Cancel")}
          >
            <Switch
              loading={isPending}
              checked={product.enable_to_sell}
              defaultChecked={product.enable_to_sell}
            />
          </Popconfirm>
        </div>
      </div>
      <div className="flex h-[3.75rem] items-center justify-center gap-x-4 border-t border-solid border-[#262626] px-4">
        <span
          className="m-0 flex w-full items-baseline justify-between p-0 text-xl font-semibold"
          onClick={execute}
        >
          {FormatCurrency({ value: product.value, currency: product.currency })}
        </span>
        {setEdit && (
          <button
            className="bg-transparent text-white transition-all duration-300 hover:scale-110 hover:text-[#FF7A00]"
            onClick={() => setEdit(product)}
          >
            <EditOutlined className="text-2xl" />
          </button>
        )}
      </div>
    </div>
  )
}
