import { common } from "@mui/material/colors"

export const duoStyle = {
  background: {
    default: "#0F0F10",
    secondary: "#141414",
    tertiary: "#1B1B1B",
    error: "#1F0002",
    warning: "#26210F",
    success: "#112119",
  },
  border: {
    default: "#242628",
  },
  primary: {
    main: "#FF7A00",
    contrastText: common.white,
    base: "#DCDCDC",
    description: "#7D7D7D",
    neutral: "#AAADB2",
  },
  secondary: {
    main: "#141414",
    contrastText: common.white,
  },
  error: {
    main: "#E8303D",
    secondary: "#3F0000",
  },
  warning: {
    main: "#FFB800",
    secondary: "#201700",
  },
  success: {
    main: "#6ABE39",
    secondary: "#002A14",
  },
  text: {
    primary: common.white,
    secondary: "#777",
    neutral: "#DFE0E2",
  },
}
