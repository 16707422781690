import { Button, Form, Input, Tooltip, message } from "antd"
import { TAccount, TWhisperDefault, WhisperDefaultSchema } from "../../types"
import { FormItem } from "react-hook-form-antd"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useUSer } from "../../hook/useUser"
import { PostDefaultWhisper } from "../../services/request/Whisper"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { queryClient } from "../../lib/reactQuery"
import { QuestionCircleFilled } from "@ant-design/icons"

export function DefaultWhisper() {
  const { data: user } = useUSer()
  const { t } = useTranslation()
  const [messageApi, contextHolder] = message.useMessage()
  const { control, handleSubmit } = useForm<TWhisperDefault>({
    resolver: zodResolver(WhisperDefaultSchema),
  })

  const { mutateAsync: mutateAsyncPostDefaultWhisper, isPending } = useMutation(
    {
      mutationFn: PostDefaultWhisper,
    },
  )
  const handleSave = async (content: TWhisperDefault) => {
    try {
      mutateAsyncPostDefaultWhisper(
        { userId: user?.id!, content },
        {
          onSuccess: () => {
            const cached = queryClient.getQueryData<TAccount>(["user"])
            queryClient.setQueryData<TAccount>(
              ["user"],
              cached && { ...cached, whisper_message: content.whisper_message },
            )
            messageApi.success(t("Profile.DefaultWhisper.Success"))
          },
        },
      )
    } catch (error) {
      messageApi.error(t("Profile.DefaultWhisper.error"))
    }
  }

  return (
    <Form
      onFinish={handleSubmit(handleSave)}
      layout="vertical"
      className="w-full"
    >
      {contextHolder}
      <FormItem
        control={control}
        name="whisper_message"
        label={
          <div className="flex flex-col">
            <div className="flex gap-x-1 text-base font-bold uppercase text-white">
              {t("Profile.DefaultWhisper.title")}
              <Tooltip title={t("Profile.DefaultWhisper.tooltip")}>
                <QuestionCircleFilled />
              </Tooltip>
            </div>
            <p className="mb-2 mt-2 text-description">
              {t("Profile.DefaultWhisper.subtitle")}
            </p>
          </div>
        }
      >
        <Input.TextArea
          key={user?.whisper_message}
          rows={4}
          defaultValue={user?.whisper_message}
          maxLength={300}
          autoSize={{ minRows: 2, maxRows: 4 }}
          className="w-full rounded-sm border border-[#262626] bg-[#1B1B1B] p-4"
        />
      </FormItem>
      <Button
        disabled={isPending}
        loading={isPending}
        type="primary"
        className="h-10 w-full rounded-sm font-bold uppercase"
        htmlType="submit"
        size="large"
      >
        {t("Profile.DefaultWhisper.save")}
      </Button>
    </Form>
  )
}
