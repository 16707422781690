import { WarningOutlined } from "@ant-design/icons"
import { Button, Flex } from "antd"
import { useTranslation } from "react-i18next"

type Props = {
  closeError: () => void
}
export function ErrorWhisper({ closeError }: Props) {
  const { t } = useTranslation()
  return (
    <Flex
      vertical
      gap="0.5rem"
      align="center"
      style={{
        width: "100%",
        marginTop: "2rem",
        marginBottom: "2rem",
      }}
    >
      <Flex gap="0.5rem">
        <WarningOutlined style={{ color: "red" }} />
        <p
          style={{
            fontSize: "14px",
            color: "#7D7D7D",
            margin: "0",
          }}
        >
          {t("Whisper.Error.PhoneNumber")}
        </p>
      </Flex>
      <Flex gap="0.5rem">
        <WarningOutlined style={{ color: "red" }} />
        <p
          style={{
            fontSize: "14px",
            color: "#7D7D7D",
            margin: "0",
          }}
        >
          {t("Whisper.Error.UserFollowing")}
        </p>
      </Flex>
      <Flex gap="0.5rem" justify="end" style={{ width: "100%" }}>
        <Button onClick={closeError}>{t("Whisper.TryAgain")}</Button>
      </Flex>
    </Flex>
  )
}
