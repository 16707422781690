import { Divider, Image, Typography } from "antd"
import { useTranslation } from "react-i18next"
import { useUSer } from "../../hook/useUser"
import { CreateProductImageURL } from "../../utils/imageHelper"
import { ShareOutlined } from "@mui/icons-material"
import clsx from "clsx"

const MATCH_TO_PLAY_IMAGE_SIZE = {
  width: 768,
  height: 800,
}

export function OnProduct() {
  const { t } = useTranslation()
  const { data: user } = useUSer()

  return (
    <>
      <div className="flex h-[400px] w-full items-stretch justify-center overflow-hidden">
        {user?.current_match?.thumbnail_url && (
          <Image
            width={MATCH_TO_PLAY_IMAGE_SIZE.width}
            height={MATCH_TO_PLAY_IMAGE_SIZE.height}  
            className="h-full w-full object-cover"
            src={CreateProductImageURL(
              user.current_match.game_id,
              MATCH_TO_PLAY_IMAGE_SIZE.width,
              MATCH_TO_PLAY_IMAGE_SIZE.height,
            )}
            alt="Picture of game"
            preview={false}
          />
        )}
      </div>
      <div
        className={clsx(
          "flex h-16 items-center justify-between bg-success p-4",
          !user?.current_match && "grayscale",
        )}
      >
        <div className="flex items-center gap-x-1 text-black">
          <p className="m-0 text-base font-semibold text-black">
            {user?.current_match ? t("live") : t("Aside.OffLine")}
          </p>
        </div>
        {user?.current_match && (
          <button className="flex h-8 w-[7.75rem] cursor-pointer items-center gap-x-2 rounded-sm border-[1.5px] border-solid border-black bg-success text-[.625rem] font-bold uppercase text-black">
            {t("Share")}
            <ShareOutlined className="text-base text-black" />
          </button>
        )}
      </div>
      <div className="px-4">
        <Typography.Title level={3} className="m-0 mt-4 text-xl">
          {user?.current_match?.game_name}
        </Typography.Title>
        <p className="m-0 mt-4 text-neutral">{user?.current_match?.title}</p>
      </div>
      <Divider className="m-0 my-4" />
    </>
  )
}
