export function TwitchICon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.3 3.2L3.7 6.4v12.8h4v2.4h3.2l2.4-2.4h3.2l4.8-4.8V3.2h-16zm1.6 1.6h12.8v8.8L17.3 16h-4.8l-2.4 2.4V16H6.9V4.8zm4 2.4v5.6h1.6V7.2h-1.6zm3.2 0v5.6h1.6V7.2h-1.6z"
        fill="#fff"
      />
    </svg>
  )
}
