import { StyledEngineProvider } from "@mui/material/styles"
import { Routes } from "./routes/Routes"
import { StyleProvider } from "./styles/StyleProvider"
import { QueryClientProvider } from "@tanstack/react-query"
import { queryClient } from "./lib/reactQuery"
import { Providers } from "./services/providers"
import { InitialHelmet } from "./components/InitialHelmet"

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <StyleProvider>
          <Providers>
            <InitialHelmet />
            <Routes />
          </Providers>
        </StyleProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  )
}
