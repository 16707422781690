import CopyToClipboard from "react-copy-to-clipboard"
import { useTranslation } from "react-i18next"
import { useUSer } from "../../hook/useUser"
import { Button, message, Tooltip } from "antd"
import { QuestionCircleFilled } from "@ant-design/icons"
export function CopyProfileLink() {
  const { t } = useTranslation()
  const { data: user } = useUSer()
  const [messageAlert, contextHolder] = message.useMessage()
  const SHARE_LINK = window.location.host + "/" + user?.name
  return (
    <>
      {contextHolder}
      <div className="flex flex-col">
        <h2 className="m-0 flex gap-x-2 text-base font-semibold uppercase text-white">
          {t("Profile.PublicProfile.title")}
          <Tooltip title={t("Profile.OBS.tooltip")}>
            <QuestionCircleFilled />
          </Tooltip>
        </h2>
        <p className="mb-0 mt-4 text-description">
          {t("Profile.ShareLinkOtherPlayers")}
        </p>
        <div className="mt-6 flex h-10 justify-between">
          <span className="flex w-[19rem] items-center border border-solid border-[#262626] bg-secondary pl-2 font-mono text-xs text-description">
            {SHARE_LINK}
          </span>
          <CopyToClipboard
            text={SHARE_LINK}
            onCopy={() => messageAlert.success("Link copiado")}
          >
            <Button className="flex h-full w-52 animate-pulse cursor-pointer items-center justify-center rounded-sm border-2 border-solid border-[#FF7A00] bg-transparent font-semibold uppercase text-[#FF7A00]">
              {t("Profile.CopySaleLink")}
            </Button>
          </CopyToClipboard>
        </div>
      </div>
    </>
  )
}
