export const Platforms = [
  {
    value: "RIOT",
    label: "Riot",
  },
  {
    value: "STEAM",
    label: "Steam",
  },
  {
    value: "EPIC",
    label: "Epic",
  },
  {
    value: "GOG",
    label: "GOG",
  },
  {
    value: "GARENA",
    label: "Garena",
  },
  {
    value: "BLIZZARD",
    label: "Blizzard",
  },
  {
    value: "PSN",
    label: "PSN",
  },
  {
    value: "XBOX_LIVE",
    label: "Xbox Live",
  },
  {
    value: "MICROSOFT_STORE",
    label: "Microsoft Store",
  },
  {
    value: "UPLAY",
    label: "Uplay",
  },
  {
    value: "ORIGIN",
    label: "Origin",
  },
  {
    value: "BATTLENET",
    label: "Battlenet",
  },
  {
    value: "GOOGLE_PLAY_STORE",
    label: "Google Play Store",
  },
  {
    value: "APPLE_APP_STORE",
    label: "Apple App Store",
  },
  {
    value: "NINTENDO",
    label: "Nintendo",
  },
  {
    value: "AMAZON_LUNA",
    label: "Amazon Luna",
  },
]
