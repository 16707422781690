import { useQuery } from "@tanstack/react-query"
import { GetAccountByNickname } from "../../services/request/Account"
import { TAccount, TProduct } from "../../types"
import { useAuthContext } from "../../context/auth"
import { GetProduct } from "../../services/request/Product"
import { useLocation } from "react-router-dom"

type TPaymentResponse = {
  product?: TProduct
  talent?: TAccount
  isLoading: boolean
  paymentError?: string
}

export function PaymentVerify(): TPaymentResponse {
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const productId = params.get("product_id")
  const nickNameTalent = params.get("talent")

  let paymentError: string | undefined
  const { user } = useAuthContext()

  const { data: product } = useQuery({
    queryKey: ["GetProduct"],
    queryFn: () => GetProduct(productId!),
    enabled: !!productId,
  })

  const { data: talent } = useQuery({
    queryKey: ["GetTalent"],
    queryFn: async () => await GetAccountByNickname(nickNameTalent!),
    enabled: !!nickNameTalent,
  })

  const isLoading = product === undefined || talent === undefined
  if (!isLoading) {
    if (product.account_id === user.userId) {
      paymentError = "You cannot buy your own product"
    }
    // else if(!talent.current_match){
    //     paymentError = 'Talent is not online'
    // }
    else if (!product) {
      paymentError = "Product Not Found"
    } else if (product.amount === 0) {
      paymentError = "Product is sold out"
    }
  }
  return { product, talent, isLoading, paymentError }
}
