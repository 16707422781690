import {
  PaymentElement,
  LinkAuthenticationElement,
} from "@stripe/react-stripe-js"
import { useState } from "react"
import { useStripe, useElements } from "@stripe/react-stripe-js"
import { Spin } from "antd"
import { useTranslation } from "react-i18next"
import { LoadingOutlined, WarningOutlined } from "@ant-design/icons"

export function CheckoutForm({ orderId }: { orderId: string }) {
  const stripe = useStripe()
  const elements = useElements()
  const { t } = useTranslation()
  const [message, setMessage] = useState<string>("")
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    if (!stripe || !elements) {
      return
    }
    setIsLoading(true)
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/completion?order=${orderId}`,
      },
    })
    if (error) {
      setMessage(error.message || "An unexpected error occurred.")
    }
    setIsLoading(false)
  }
  return (
    <>
      <form
        className="flex w-full flex-col"
        id="payment-form"
        onSubmit={handleSubmit}
      >
        {message && (
          <div className="flex items-center my-2 text-error font-semibold gap-x-2" id="payment-message">
            <WarningOutlined />
            {message}
          </div>
        )}
        <LinkAuthenticationElement
          className="mb-4"
          id="link-authentication-element"
        />
        <PaymentElement id="payment-element" />
        {stripe && (
          <div className="mb-4 mt-8 flex items-center justify-end">
            <button
              disabled={isLoading}
              className="h-8 w-52 cursor-pointer rounded-sm bg-orange-500 text-sm font-bold uppercase text-black transition-all hover:scale-105"
              id="submit"
            >
              {isLoading ? (
                <Spin
                  indicator={<LoadingOutlined spin className="text-black" />}
                />
              ) : (
                <div>{t("CheckOut.Payment.ConfirmPayment")}</div>
              )}
            </button>
          </div>
        )}
      </form>
    </>
  )
}
