import { Spin } from "antd"
import { CompletionVerify } from "./CompletionVerify"
import { PaymentSuccess } from "../../components/Payment/PaymentSuccess"
import { PaymentError } from "../../components/Payment/PaymentError"
import { Helmet } from "react-helmet"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"

export function Completion() {
  const { isLoading, order } = CompletionVerify()
  const { t } = useTranslation()
  const location = useLocation()

  if (isLoading) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Spin size="large" />
      </div>
    )
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Helmet.payment.title")}</title>
        <meta name="description" content={t("Helmet.payment.description")} />
        <link rel="canonical" href={origin + location.pathname} />
      </Helmet>
      <div className="mx-auto flex max-w-screen-lg justify-center">
        {order ? <PaymentSuccess order={order} /> : <PaymentError />}
      </div>
    </>
  )
}
