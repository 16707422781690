import { ReactNode } from "react"
import { useTranslation } from "react-i18next"

type PropIsOnline = {
  title: ReactNode
  online: boolean
}

export default function IsOnline({ title, online }: PropIsOnline) {
  const { t } = useTranslation()

  return (
    <div className="flex justify-center gap-x-2">
      {online ? (
        <>
          <div className="h-[.875rem] w-[.875rem] animate-pulse rounded-full bg-success"></div>
          <span className="text-description">{title}</span>
        </>
      ) : (
        <>
          <div className="h-[.875rem] w-[.875rem] animate-pulse rounded-full bg-neutral"></div>
          <span className="text-description">{t("sidebar.offline")}</span>
        </>
      )}
    </div>
  )
}
