import { Select } from "antd"
import ReactCountryFlag from "react-country-flag"
import { useTranslation } from "react-i18next"

export const ChangeLang = () => {
  const { i18n } = useTranslation()
  const handleLangChange = () => {
    const lang = i18n.language === "pt" ? "en" : "pt"
    i18n.changeLanguage(lang)
    localStorage.setItem("@DuoToPlay:lang", lang)
  }

  const Langs = [
    {
      value: "pt",
      label: "pt-BR",
      flag: "BR",
    },
    {
      value: "en",
      label: "en-US",
      flag: "US",
    },
  ]
  return (
    <Select
      className="h-8 w-24 p-0"
      value={i18n.language}
      onChange={handleLangChange}
      options={Langs.map((lang) => ({
        value: lang.value,
        label: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: ".25rem",
              fontSize: "12px",
            }}
          >
            <ReactCountryFlag
              svg
              countryCode={lang.flag}
              alt={`${lang.label} flag`}
              width={16}
              height={16}
            />
            {lang.label}
          </div>
        ),
      }))}
    />
  )
}
