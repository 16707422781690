import { Divider } from "antd"
import { ReactNode } from "react"
import { Link } from "react-router-dom"
import { InstagramIcon, TwitchICon } from "../Icons"
import { Trans, useTranslation } from "react-i18next"
import { env } from "../../utils/env"

type Props = {
  Header: ReactNode
  Body: ReactNode
}

export default function CardPayment({ Header, Body }: Props) {
  const { t } = useTranslation()
  return (
    <div className="mx-4 my-10 max-w-full md:mx-auto md:max-w-[45rem]">
      <div className="flex h-[8.25rem] w-full items-center bg-tertiary px-4 md:px-10">
        {Header}
      </div>
      <div className="flex w-full flex-col bg-secondary p-4 md:p-10">
        {Body}
        <p className="m-0 mt-6 text-base">
          <Trans
            tOptions={{ contact: env.DUO_EMAIL_CONTACT }}
            i18nKey={"Payment.SupportMessage"}
          >
            Lets go
            <a
              href={"mailto:" + env.DUO_EMAIL_CONTACT}
              className="text-cyan-500"
            >
              contact us
            </a>
          </Trans>
        </p>
        <Divider className="my-4" />
        <div className="flex flex-col items-end justify-center gap-y-[0.625rem] font-bold uppercase">
          {t("SocialMedia")}
          <div>
            <Link to={env.DUO_INSTAGRAM} target="_blank">
              <InstagramIcon />
            </Link>
            <Link to={env.DUO_TWITCH} target="_blank">
              <TwitchICon />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
