import clsx from "clsx"
import { TwitchICon } from "../../components/Icons"

export const ObsMessage = ({
  talent_name,
  category,
  client_name,
  active,
}: {
  talent_name: string
  category: string
  client_name: string
  active?: boolean
}) => {
  return (
    <div
      className={clsx(
        "px flex h-screen w-screen items-center justify-center border-solid bg-orange-500 bg-opacity-10 px-[5%] text-center text-6xl leading-[5rem] uppercase text-white",
        active === undefined
          ? "hidden"
          : active
            ? "animate-fade-in"
            : "animate-fade-out",
      )}
    >
      <p>
        <strong className="inline text-blue-600">{client_name}</strong> comprou
        uma duotoplay de <strong className="text-orange-500">{category}</strong>{" "}
        com <strong className="text-[#9146FF]">{talent_name}</strong>
        <TwitchICon fontSize={"3rem"} />
      </p>
    </div>
  )
}
