import { Modal } from "antd"
import { useTranslation } from "react-i18next"

type DuoClientInviteModalProps = {
  isModalOpen: boolean
  handleOk: () => void
  handleCancel: () => void
}

export const MatchUninvitedModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
}: DuoClientInviteModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal
      title={t("Matches.Modal.Uninvited.CancelToUninvited")}
      open={isModalOpen}
      styles={{
        header: {
          backgroundColor: "#1B1B1B",
        },
        content: {
          backgroundColor: "#1B1B1B",
        },
      }}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={t("Matches.Modal.Uninvited.Yes")}
      cancelText={t("Matches.Modal.Uninvited.CancelToUninvited")}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          gap: "2rem",
          marginBottom: "2rem",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            backgroundColor: "#141414",
            width: "100%",
            height: "fit-content",
            borderRadius: "4px",
            alignItems: "center",
          }}
        >
          <p
            style={{
              color: "white",
              fontSize: "1.5rem",
              fontWeight: "bold",
              marginLeft: "1rem",
              marginBottom: "1rem",
              margin: "1rem",
            }}
          >
            {t("Matches.Modal.Uninvited.AskCancelToUninvited")}
          </p>
        </div>
      </div>
    </Modal>
  )
}
