import { Button, Flex, Form, Input, Tooltip } from "antd"
import { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import { TPlayer, PlayerSchema } from "../../../types"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { FormItem } from "react-hook-form-antd"
import { QuestionCircleFilled } from "@ant-design/icons"
import clsx from "clsx"

type Props = {
  setData: Dispatch<SetStateAction<TPlayer | undefined>>
  disabled?: boolean
}
export function FirstStep({ setData, disabled }: Props) {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<TPlayer>({
    resolver: zodResolver(PlayerSchema(t)),
  })
  const handleSave = async (personalData: Partial<TPlayer>) => {
    setData(personalData as TPlayer)
  }
  return (
    <Form
      className={clsx(
        "w-full transition-all duration-300",
        disabled && "hidden animate-fade-out",
      )}
      layout="vertical"
      onFinish={handleSubmit(handleSave)}
      size="middle"
    >
      <FormItem
        control={control}
        name="username"
        label={
          <div className="flex gap-x-2 text-base font-semibold">
            {t("CheckOut.GameAccount.Username.title")}
            <Tooltip title={t("CheckOut.GameAccount.Username.Tooltip")}>
              <QuestionCircleFilled />
            </Tooltip>
          </div>
        }
        style={{ width: "100%" }}
      >
        <Input placeholder="FalleN" className="rounded-sm bg-primary" />
      </FormItem>
      <Flex justify="end">
        <Button
          htmlType="submit"
          className={clsx(
            "rounded-sm font-bold uppercase",
            isValid ? "bg-success text-black" : "bg-primary",
          )}
        >
          {t("CheckOut.GameAccount.Advance")}
        </Button>
      </Flex>
    </Form>
  )
}
