import { useTranslation } from "react-i18next"
import { CloseOutlinedIcon } from "../Icons"
import CardPayment from "./CardPayment"

export function PaymentError() {
  const { t } = useTranslation()
  return (
    <CardPayment
      Header={
        <div className="flex items-center gap-x-6">
          <CloseOutlinedIcon />
          <p className="text-[1.625rem] font-bold">
            {t("Payment.Error.title")}
          </p>
        </div>
      }
      Body={<p className="text-base">{t("Payment.Error.description")}</p>}
    />
  )
}
