import { z } from "zod"

const envSchema = z.object({
  // DUO
  DUO_API_URL: z.string(),
  // STRIPE
  STRIPE_WALLET_URL: z.string(),
  // AWS
  AWS_ACCESS_KEY_ID: z.string(),
  AWS_SECRET_ACCESS_KEY: z.string(),
  AWS_REGION: z.string(),
  // FIREBASE
  FIREBASE_API_KEY: z.string(),
  FIREBASE_AUTH_DOMAIN: z.string(),
  FIREBASE_PROJECT_ID: z.string(),
  FIREBASE_STORAGE_BUCKET: z.string(),
  FIREBASE_MESSAGING_SENDER_ID: z.string(),
  FIREBASE_APP_ID: z.string(),
  // SOCIAL NETWORKS
  DUO_EMAIL: z.string(),
  DUO_EMAIL_CONTACT: z.string(),
  DUO_TWITTER: z.string(),
  DUO_INSTAGRAM: z.string(),
  DUO_TWITCH: z.string(),
  DUO_YOUTUBE: z.string(),
  DUO_TIKTOK: z.string(),
  DUO_LINKEDIN: z.string(),
  DUO_DISCORD: z.string(),
  // IMAGES URL
  GAME_IMAGE_TWITCH_URL: z.string(),
})

export const env = envSchema.parse({
  DUO_API_URL: process.env.REACT_APP_DUO_API_URL || "http://localhost:8000/api",
  DUO_EMAIL: process.env.REACT_APP_DUO_EMAIL || "duotoplay@gmail.com ",
  DUO_EMAIL_CONTACT: process.env.REACT_APP_DUO_EMAIL || "support@duotoplay.com",
  DUO_TWITTER:
    process.env.REACT_APP_DUO_TWITTER || "https://twitter.com/DuoToPlay",
  DUO_TWITCH:
    process.env.REACT_APP_DUO_TWITCH || "https://twitch.tv/duotoplaybr",
  DUO_INSTAGRAM:
    process.env.REACT_APP_DUO_INSTAGRAM ||
    "https://www.instagram.com/duotoplay/",
  STRIPE_WALLET_URL:
    process.env.REACT_APP_STRIPE_WALLET_URL ||
    "https://dashboard.stripe.com/balance/overview",
  AWS_ACCESS_KEY_ID:
    process.env.REACT_APP_AWS_ACCESS_KEY_ID || "AKIA2UC3DSJQBUNVOF4J",
  AWS_SECRET_ACCESS_KEY:
    process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ||
    "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",
  AWS_REGION: process.env.REACT_APP_AWS_REGION || "sa-east-1",
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
  GAME_IMAGE_TWITCH_URL:
    process.env.REACT_APP_GAME_IMAGE_TWITCH_URL ||
    "https://static-cdn.jtvnw.net/ttv-boxart/{game_id}-{width}x{height}.jpg",
  DUO_YOUTUBE: process.env.REACT_APP_DUO_YOUTUBE || "https://www.youtube.com/@DuoToPlay",
  DUO_TIKTOK: process.env.REACT_APP_DUO_TIKTOK || "https://www.tiktok.com/@duotoplay",
  DUO_LINKEDIN: process.env.REACT_APP_DUO_LINKEDIN || "https://www.linkedin.com/company/duotoplay",
  DUO_DISCORD: process.env.REACT_APP_DUO_DISCORD || "https://discord.gg/eRdchgBeUk",
})
