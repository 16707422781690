import { env } from "./env"
export const formatImage = (image: string, width: number, height: number) => {
  return image
    .replace("{width}", width.toString())
    .replace("{height}", height.toString())
}

export const CreateProductImageURL = (
  game_id: string,
  width: number,
  height: number,
) => {
  const game_url = env.GAME_IMAGE_TWITCH_URL.replace(
    "{width}",
    width.toString(),
  )
    .replace("{height}", height.toString())
    .replace("{game_id}", game_id)
  return game_url
}
