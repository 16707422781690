import { useState } from "react"
import { Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import { MatchModalCreate } from "../../views/MatchModalCreate"

export const CreateMatchCard = ({ refetch }: { refetch: () => void }) => {
  const [showCreateMatch, setShowCreateMatch] = useState(false)
  const { t } = useTranslation()
  return (
    <>
      <MatchModalCreate
        open={showCreateMatch}
        refresh={refetch}
        handleClose={() => setShowCreateMatch(false)}
      />
      <Button
        onClick={() => {
          setShowCreateMatch(true)
        }}
      >
        <div className="flex h-[27rem] w-[16rem] cursor-pointer flex-col items-center justify-center rounded-[2px] border-2 border-dashed border-orange-500 hover:border-orange-300 hover:text-orange-300">
          <p className="text-base font-bold uppercase text-orange-500">
            + {t("Duo.create")}
          </p>
        </div>
      </Button>
    </>
  )
}
