import { useUSer } from "../../hook/useUser"
import { UserPicture } from "../../components/UserPicture"
import { CopyObsLink } from "./CopyObsLink"
import { CopyProfileLink } from "./CopyProfileLink"
import { DefaultWhisper } from "./WhisperMessage"
import { Link, useLocation } from "react-router-dom"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"

export default function Profile() {
  const { data: user } = useUSer()
  const { t } = useTranslation()
  const location = useLocation()

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Helmet.profile.title")}</title>
        <meta
          name="description"
          content={t("Helmet.profile.title")}
        />
        <link rel="canonical" href={origin + location.pathname} />
      </Helmet>
      <div className="mx-auto flex w-[38rem] flex-col items-center">
        <div className="flex w-full flex-col items-center bg-secondary p-8">
          <div className="flex flex-col items-center">
            <Link to={`/${user?.name}`}>
              <UserPicture alt={user?.name} src={user?.avatar} />
            </Link>
            <Link to={`/${user?.name}`}>
              <h1 className="mb-0 mt-8 text-center text-xl font-bold text-white">
                @{user?.name}
              </h1>
            </Link>
            <span className="mt-4 text-description">{user?.email}</span>
            <p className="m-0 mt-4 line-clamp-4 h-[5.7rem] w-full overflow-hidden text-ellipsis text-center text-sm leading-6 text-neutral">
              {user?.profile_description}
            </p>
          </div>
          <DefaultWhisper />
        </div>

        <div className="mt-8 flex w-full flex-col bg-secondary p-8">
          <CopyProfileLink />
          <CopyObsLink />
        </div>
      </div>
    </>
  )
}
