import { ClockCircleOutlined, RightSquareOutlined } from "@ant-design/icons"
import { useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { CreateMatchCard } from "../../components/Match/ButtonCreateMatch"
import { ProductCard } from "../../components/Product/ProductCard"
import { ProductPlayedCard } from "../../components/Product/ProductPlayedCard"
import { useAuthContext } from "../../context/auth"
import { useIsUserOnlineOnTwhtichContext } from "../../context/isOnline"
import { GetProductsByAccount } from "../../services/request/Product"
import { TProduct } from "../../types"
import { MatchesPanel } from "../../views/MatchesPanel/MatchesPanel"
import CardProductSkeleton from "../../components/Skeloton"
import { MatchModalUpdate } from "../../views/MatchModalUpdate"

export default function MatchesPage() {
  const { user } = useAuthContext()
  const { setIsOnline } = useIsUserOnlineOnTwhtichContext()
  const [editProduct, setEditProduct] = useState<TProduct>()
  const { t } = useTranslation()

  const {
    data: products,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["getProducts"],
    queryFn: () => GetProductsByAccount(user.userId),
  })

  useEffect(() => {
    if (!products) return
    const isOnline = products.some((product) => product.online === false)
    setIsOnline(isOnline)
  }, [products, setIsOnline])

  return (
    <>
      <MatchModalUpdate
        open={!!editProduct}
        edit={editProduct!}
        refresh={() => refetch()}
        handleClose={() => setEditProduct(undefined)}
      />
      <div className="flex flex-col gap-y-8">
        <MatchesPanel
          title={
            <div className="flex items-center justify-between">
              <p className="m-0 mb-6 text-[1.625rem] font-bold">
                <RightSquareOutlined className="mr-2 text-white" />
                {t("Products")}
              </p>
            </div>
          }
        >
          <div className="flex flex-wrap gap-6 overflow-hidden">
            {isLoading ? (
              Array.from({ length: 5 }).map((_, index) => (
                <CardProductSkeleton key={index} />
              ))
            ) : products ? (
              products.map(
                (product) =>
                  product.amount > 0 && (
                    <ProductCard
                      product={product}
                      key={product.id}
                      execute={() => {
                        setEditProduct(product)
                      }}
                    />
                  ),
              )
            ) : (
              <CreateMatchCard refetch={() => refetch()} />
            )}
          </div>
        </MatchesPanel>
        <MatchesPanel
          title={
            <div className="flex items-center justify-between">
              <p className="mt-0 text-[1.625rem]">
                <ClockCircleOutlined className="mr-2 text-neutral" />
                {t("Resume.matchesPlayed")}
              </p>
            </div>
          }
        >
          <div className="flex flex-wrap items-center gap-6 overflow-hidden">
            {!products
              ? Array.from({ length: 5 }).map((_, index) => (
                  <CardProductSkeleton key={index} />
                ))
              : products.map(
                  (product) =>
                    product.amount === 0 && (
                      <ProductPlayedCard
                        key={product.id}
                        product={product}
                        onClick={() => {
                          setEditProduct(product)
                        }}
                      />
                    ),
                )}
          </div>
        </MatchesPanel>
      </div>
    </>
  )
}
