import { z } from "zod"
import { TFunction } from "i18next"

export const productSchema = z.object({
  id: z.string(),
  title: z.string().min(3, { message: "createMatch.errors.title" }),
  name: z.string(),
  account_id: z.string(),
  description: z.string().min(3, { message: "createMatch.errors.description" }),
  amount: z.number().min(1, { message: "createMatch.errors.amount_offered" }),
  avatar: z.string(),
  value: z.number().min(1, { message: "createMatch.errors.amount" }),
  online: z.boolean().default(false),
  otimistic_counter: z.number().min(0),
  amount_offered: z.number(),
  currency: z.string().default("brl"),
  platform: z.string().optional(),
  external_id: z.string().optional(),
  enable_to_sell: z.boolean().default(false),
  device: z.string().optional(),
  region: z.string().optional(),
})

export function createProductSchema(t?: TFunction<"en", undefined>) {
  return z
    .object({
      title: z
        .string({ required_error: t?.("createMatch.errors.required") })
        .min(3, { message: t?.("createMatch.errors.title") }),
      description: z
        .string({ required_error: t?.("createMatch.errors.required") })
        .min(3, { message: t?.("createMatch.errors.description") }),
      amount: z
        .number({ required_error: t?.("createMatch.errors.required") })
        .min(1, { message: t?.("createMatch.errors.amount_offered") }),
      product_description_id: z
        .string({ required_error: t?.("createMatch.errors.required") })
        .min(1, { message: t?.("createMatch.errors.product_description_id") }),
      value: z
        .number({ required_error: t?.("createMatch.errors.required") })
        .min(1, { message: t?.("createMatch.errors.amount") }),
      currency: z.string().default("brl"),
      platform: z.string().optional(),
      device: z.string().optional(),
      region: z.string().optional(),
    })
    .refine((data) => !(data.currency === "brl" && data.value < 500), {
      message: t?.("createMatch.errors.value.min", {
        value: 5,
        currency: "BRL",
      }),
      path: ["value"],
    })
    .refine((data) => !(data.currency === "usd" && data.value < 100)  , {
      message: t?.("createMatch.errors.value.min", {
        value: 1,
        currency: "USD",
      }),
      path: ["value"],
    })
}
export function updateProductSchema(t?: TFunction<"en", undefined>) {
  return z
    .object({
      id: z.string(),
      title: z
        .string({ required_error: t?.("createMatch.errors.required") })
        .min(3, { message: t?.("createMatch.errors.title") }),
      description: z
        .string({ required_error: t?.("createMatch.errors.required") })
        .min(3, { message: t?.("createMatch.errors.description") }),
      amount: z
        .number({ required_error: t?.("createMatch.errors.required") })
        .min(1, { message: t?.("createMatch.errors.amount_offered") }),
      value: z
        .number({ required_error: t?.("createMatch.errors.required") })
        .min(1, { message: t?.("createMatch.errors.amount") }),
      currency: z.string().default("brl"),
      platform: z.string().optional(),
      device: z.string().optional(),
      region: z.string().optional(),
      enable_to_sell: z.boolean().default(false),
    })
    .refine((data) => !(data.currency === "brl" && data.value < 500), {
      message: t?.("createMatch.errors.value.min", {
        value: '5,00',
        currency: "BRL",
      }),
      path: ["value"],
    })
    .refine((data) => !(data.currency === "usd" && data.value < 100), {
      message: t?.("createMatch.errors.value.min", {
        value: '1,00',
        currency: "USD",
      }),
      path: ["value"],
    })
}

export type TUpdateProduct = z.infer<ReturnType<typeof updateProductSchema>>
export type TCreateProduct = z.infer<ReturnType<typeof createProductSchema>>
export type TProduct = z.infer<typeof productSchema>
