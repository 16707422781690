import { useTranslation } from "react-i18next"
import { TProduct } from "../../types"
import { formatImage } from "../../utils/imageHelper"
import { FormatCurrency } from "../../utils/currencyHelper"
import { clsx } from "clsx"
import { message } from "antd"
import { useState } from "react"
import { useAuthContext } from "../../context/auth"
import { MatchModalUpdate } from "../../views/MatchModalUpdate"

interface ActiveCardProps {
  product: TProduct
  execute: () => void
  refetch: () => void
}

const AVATAR_IMAGE_SIZE = {
  width: 254,
  height: 202,
}

export const ProductClientCard = ({
  product,
  execute,
  refetch,
}: ActiveCardProps) => {
  const { t } = useTranslation()
  const { user } = useAuthContext()

  const [messageAlert, contextHolder] = message.useMessage()
  const [editProduct, setEditProduct] = useState<TProduct>()

  return (
    <>
      {contextHolder}
      <MatchModalUpdate
        edit={editProduct!}
        open={!!editProduct}
        refresh={() => refetch()}
        handleClose={() => setEditProduct(undefined)}
      />
      <div
        className={clsx(
          "relative flex h-[26rem] w-[15.875rem] flex-shrink-0 cursor-pointer flex-col justify-between rounded-sm bg-tertiary shadow transition-all duration-200 hover:scale-105",
          product.online && "border-2 border-solid border-success",
        )}
        onClick={() => {
          if (user.userId === product.account_id) {
            return setEditProduct(product)
          } else if (!product.online) {
            return messageAlert.info(t("Product.Offline"))
          } else if (product.amount <= 0) {
            return messageAlert.error(t("Product.OutOfStock"))
          }
          execute()
        }}
      >
        <div className="relative flex h-[13rem] w-full flex-col">
          {product.online && (
            <div className="z-10 flex h-[1.875rem] items-center justify-center bg-bgSuccess text-center">
              <span className="text-base font-semibold text-success">
                {t("Playing")}
              </span>
            </div>
          )}
          <img
            height={AVATAR_IMAGE_SIZE.height}
            width={AVATAR_IMAGE_SIZE.width}
            src={formatImage(
              product.avatar,
              AVATAR_IMAGE_SIZE.width,
              AVATAR_IMAGE_SIZE.height,
            )}
            alt={product.name}
            className={clsx(
              "w-full animate-fade-in rounded-t-sm object-cover",
              !product.online && "grayscale",
            )}
          />
        </div>
        <div className="flex h-[13rem] flex-col justify-between px-4 py-4">
          <h2 className="m-0 mb-2 truncate text-base font-semibold text-description">
            {product.title} {product.device && "| " + product.device}
          </h2>
          <p className="m-0 mb-2 break-words text-sm text-description">
            {product.description}
          </p>
          <div
            className={clsx(
              "inline-flex h-8 items-center rounded-sm border border-solid px-2 text-base",
              product.amount === 0 &&
                "border-error bg-errorSecondary text-error",
              product.amount <= 3 &&
                product.amount > 0 &&
                "border-warning bg-bgWarningSecondary text-warning",
              product.amount >= 4 &&
                "border-success bg-bgSuccessSecondary text-success",
            )}
          >
            {product.amount > 0
              ? `${t("AvailableVacancies")} ${product.amount}`
              : t("SoldOff")}
          </div>
        </div>
        <div className="flex h-[3.75rem] items-center justify-center gap-x-4 border-t border-solid border-[#262626] px-4">
          <span className="m-0 flex w-full items-baseline justify-between p-0 text-xl font-semibold">
            {FormatCurrency({
              value: product.value,
              currency: product.currency,
            })}
            {/*   */}
          </span>
        </div>
      </div>
    </>
  )
}
