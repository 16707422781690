import { useState } from "react"
import { Layout, Menu } from "antd"
import { Button } from "@mui/material"
import { useNavigate, useLocation, Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import LogoutIcon from "@mui/icons-material/Logout"
import { useAuthContext } from "../../../context/auth"
import { AppstoreOutlined, UserOutlined } from "@ant-design/icons"
import { useQuery } from "@tanstack/react-query"
import { GetProductsByAccount } from "../../../services/request/Product"
import IsOnline from "../../IsOnline"
import { useUSer } from "../../../hook/useUser"
import { UserPicture } from "../../UserPicture"
import { MatchModalCreate } from "../../../views/MatchModalCreate"

const { Sider } = Layout

type SidebarProps = {
  collapsed: boolean
  setCollapsed: (collapsed: boolean) => void
}

export const Sidebar = ({ collapsed, setCollapsed }: SidebarProps) => {
  const { logout } = useAuthContext()
  const { data: user } = useUSer()

  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [showCreateMatch, setShowCreateMatch] = useState(false)

  const { refetch } = useQuery({
    queryKey: ["getProducts"],
    queryFn: () => GetProductsByAccount(user!.id),
  })

  const menuRoutes = [
    {
      key: "/portal/resume",
      icon: <AppstoreOutlined />,
      label: t(`Resume.title`),
    },
    {
      key: "/portal/profile",
      icon: <UserOutlined />,
      label: t(`Profile.title`),
    },
  ]

  const handleMenuClick = ({ key }: { key: string }) => {
    return navigate(key)
  }
  return (
    <Sider
      width={320}
      breakpoint="lg"
      collapsedWidth="0"
      trigger={false}
      collapsed={collapsed}
      onBreakpoint={(broken) => {
        setCollapsed(broken)
      }}
      onCollapse={() => setCollapsed(!collapsed)}
      zeroWidthTriggerStyle={{ display: "none" }}
      className="h-[calc(100vh-3.75rem)] bg-secondary"
    >
      <div
        dir="rtl"
        className="flex h-full w-full scroll-m-[2px] flex-col justify-between overflow-y-auto"
      >
        <div dir="ltr">
          <div className="mt-14 text-center">
            <Link to={`/${user?.name}`}>
              <UserPicture alt={user?.avatar} src={user?.avatar} />
            </Link>
            <div className="my-8 flex h-14 flex-col justify-between">
              <Link to={`/${user?.name}`}>
                <p className="text-1xl font-bold tracking-wide text-white">
                  @{user?.name}
                </p>
              </Link>
              <IsOnline
                title={t("sidebar.online")}
                online={!!user?.current_match}
              />
            </div>
          </div>

          <div className="flex flex-col items-center justify-center">
            <Button
              onClick={() => setShowCreateMatch(true)}
              variant="contained"
              className="mb-8 h-[2.5rem] w-[12.5rem] rounded-[2px] text-black"
            >
              + {t("createMatch.title")}
            </Button>
            <Button
              onClick={() => window.open("/talents/all", "_blank")}
              className="mb-8 h-[2.5rem] w-[12.5rem] rounded-[2px] bg-success text-black"
            >
              {t("sidebar.streamers")}
            </Button>
            <Menu
              onClick={handleMenuClick}
              defaultOpenKeys={[location.pathname]}
              mode="inline"
              items={menuRoutes}
              className="w-[12.5rem]"
            />
          </div>
        </div>
        <div dir="ltr" className="flex border-t border-solid border-gray-800">
          <Button
            onClick={() => logout(true)}
            className="bottom-0 ml-8 flex justify-start text-description"
          >
            <LogoutIcon fontSize="small" className="mr-2" />
            <p className="text-xs">{t("sidebar.logout")}</p>
          </Button>
        </div>
      </div>
      <MatchModalCreate
        open={showCreateMatch}
        handleClose={() => setShowCreateMatch(false)}
        refresh={() => refetch()}
      />
    </Sider>
  )
}
