import { useTranslation } from "react-i18next"
import { TOrder } from "../../types"
import { FormatCurrency } from "../../utils/currencyHelper"
import { CheckOutlinedIcon } from "../Icons"
import { formatImage } from "../../utils/imageHelper"
import CardPayment from "./CardPayment"
import { TwitchOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"

type Props = {
  order: TOrder
}
const AVATAR_IMAGE_SIZE = {
  width: 204,
  height: 298,
}

type PropsColumns = {
  key: string
  value: string
}

export function PaymentSuccess({ order }: Props) {
  const { t } = useTranslation()

  const Columns: Array<PropsColumns> = [
    {
      key: t("Payment.Success.category"),
      value: order.item?.name || "NA",
    },
    {
      key: t("CheckOut.CardOrder.PlayWith"),
      value: order.talent_account.name!,
    },
    {
      key: t("Payment.Success.title"),
      value: order.item.title,
    },
    {
      key: t("Payment.Success.quantity"),
      value: t("Payment.Success.vacancy", {
        quantity: 1,
      }),
    },
    {
      key: t("Payment.Success.value"),
      value: FormatCurrency({
        currency: order!.item!.currency,
        value: order!.item!.value,
      }),
    },
  ]

  return (
    <CardPayment
      Header={
        <div className="flex items-center gap-x-6">
          <CheckOutlinedIcon />
          <p className="text-[1.625rem] font-bold">
            {t("Payment.Success.done")}
          </p>
        </div>
      }
      Body={
        <div className="flex flex-col gap-y-6">
          <p className="m-0 text-base">
            {t("Payment.Success.description", {
              Buyer: order?.client_account?.name,
              Talent: order.talent_account?.name,
            })}
          </p>
          <div className="flex flex-col items-center gap-x-4 rounded-sm border-2 border-solid border-tertiary md:flex-row md:items-start">
            {" "}
            <img
              height={AVATAR_IMAGE_SIZE.height}
              width={AVATAR_IMAGE_SIZE.width}
              src={formatImage(
                order!.item!.avatar,
                AVATAR_IMAGE_SIZE.width,

                AVATAR_IMAGE_SIZE.height,
              )}
              alt={`Avatar of ${order!.item!.name}`}
              className="h-[18.625rem] w-[12.75rem] bg-cover"
            />
            <div className="flex w-full flex-col overflow-hidden">
              <div className="flex flex-col gap-y-6 px-8 py-4 text-neutral">
                {Columns.map((column, index) => (
                  <div
                    key={index}
                    className="flex h-[1.375rem] items-center justify-between gap-x-4"
                  >
                    <span className="m-0 flex text-nowrap font-bold">
                      {column.key}
                    </span>
                    <p className="truncate text-nowrap">{column.value}</p>
                  </div>
                ))}
              </div>
              <div className="flex h-14 items-center justify-between gap-x-4 border-t border-solid border-tertiary px-8 text-base font-semibold text-white">
                <span>{t("Payment.Success.total")}</span>
                <span>
                  {FormatCurrency({
                    value: order!.item!.value,
                    currency: order!.item!.currency,
                  })}
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-4">
            <span className="mb-0 flex justify-end text-base">
              <Link
                to={"/" + order.talent_account.name}
                className="hover:bg- flex items-center justify-center rounded-md bg-orange-500 px-4 py-2 font-bold text-white transition-all duration-300 hover:scale-105"
              >
                {t("Payment.Success.goBackToProfile")}
              </Link>
            </span>
            <span className="mb-0 flex justify-end text-base">
              <a
                href={"https://www.twitch.tv/" + order.talent_account.name}
                target="_blank"
                className="flex items-center justify-center rounded-md bg-[#9146FF] px-4 py-2 font-bold text-white transition-all duration-300 hover:scale-105 hover:bg-[#9146FFa1]"
                rel="noreferrer"
              >
                {t("Payment.Success.goToStream")}
                <TwitchOutlined className="ml-2" />
              </a>
            </span>
          </div>
        </div>
      }
    />
  )
}
