import { createTheme, responsiveFontSizes } from "@mui/material/styles"
import { common } from "@mui/material/colors"
import { duoStyle } from "./duoStyle"

export const materialTheme = responsiveFontSizes(
  createTheme({
    palette: {
      background: {
        default: duoStyle.background.default,
        paper: duoStyle.background.secondary,
      },
      primary: {
        main: duoStyle.primary.main,
        contrastText: common.white,
      },
      secondary: {
        main: duoStyle.secondary.main,
        contrastText: common.white,
      },
      error: {
        main: duoStyle.error.main,
      },
      warning: {
        main: duoStyle.warning.main,
      },
      text: {
        primary: common.black,
      },
    },
  }),
)
