import React from "react"
import { ThemeProvider, createTheme } from "@mui/material"
import { ConfigProvider } from "antd"
import { materialTheme } from "./materialTheme"
import { antdTheme } from "./antdTheme"
import { useTranslation } from "react-i18next"

import antEnUS from "antd/locale/en_US"
import antPtBR from "antd/locale/pt_BR"

import * as Locales from "@mui/material/locale"

export const StyleProvider = ({ children }: { children: React.ReactNode }) => {
  const { i18n } = useTranslation()
  const lang = i18n.language
  const muiLocale = lang === "pt" ? "ptBR" : "enUS"
  const antLocale = lang === "pt" ? antPtBR : antEnUS

  return (
    <ThemeProvider theme={createTheme(materialTheme, Locales[muiLocale])}>
      <ConfigProvider locale={antLocale} theme={antdTheme}>
        {children}
      </ConfigProvider>
    </ThemeProvider>
  )
}
