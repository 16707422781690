import { useContext, useEffect } from "react"
import { Trans, useTranslation } from "react-i18next"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { AuthContext } from "../../context/auth"
import { publicApi } from "../../services/api"
import { ChangeLang } from "../../components/ChangeTheme/ChangeTheme"
import { Layout } from "antd"
import { Helmet } from "react-helmet"

export function Login() {
  const {
    token,
    user: { userId },
  } = useContext(AuthContext)
  const { t } = useTranslation()
  const navigation = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (token && userId) {
      navigation("/portal")
    }
  }, [token, userId, navigation])

  async function handleAuthLink(type: string) {
    const { data } = await publicApi.get(`/authorize/third-party?type=${type}`)
    window.location.href = data.uri
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Helmet.login.title")}</title>
        <meta name="description" content={t("Helmet.login.description")} />
        <link rel="canonical" href={origin + location.pathname} />
      </Helmet>
      <Layout>
        <div className="absolute flex w-full items-center justify-between pt-4 text-white">
          <span className="pl-8 text-xl font-bold uppercase tracking-widest">
            DuoToPlay
          </span>
          <div className="pr-8">
            <ChangeLang />
          </div>
        </div>
        <div className="flex h-screen flex-col md:flex-row">
          <img
            width="100%"
            height="100%"
            src="/img/login-banner.png"
            alt="Login gamer"
            className="h-1/3 md:h-screen md:w-3/5"
          />
          <div className="flex flex-col justify-center bg-primary px-24 text-white md:w-2/5">
            <div className="mt-[2vh] flex w-full flex-col justify-center md:mt-0">
              <h1 className="m-0 w-full text-4xl leading-[3rem]">
                {t("login.title")}
              </h1>
              <p className="mb-8 mt-4 text-base font-thin">
                {t("login.description")}
              </p>
              <div
                className="mt-10 flex cursor-pointer items-center justify-center rounded-[2px] bg-[#9146FF] text-sm font-bold uppercase hover:bg-[#9146FFa1]"
                onClick={() => handleAuthLink("twitch")}
              >
                <img
                  width={24}
                  height={24}
                  src="/img/twitch.svg"
                  alt="Twitch icon"
                  className="mr-1 h-[24px] w-auto"
                />
                <p>{t("login.twitch")}</p>
              </div>
            </div>
            <p className="m-0 mt-6 text-base font-thin">
              <Trans i18nKey="login.agreeTerms">
                <Link
                  to="/terms"
                  className="font-extrabold text-neutral underline"
                ></Link>
              </Trans>
            </p>
          </div>
        </div>
      </Layout>
    </>
  )
}
