import { Search } from "@mui/icons-material"
import { useQuery } from "@tanstack/react-query"
import { Button, Input, Spin } from "antd"
import { useTranslation } from "react-i18next"
import { GetMatchesByProductId } from "../../services/request/Matches"
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react"
import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone"
import { TMatch, TProduct } from "../../types"
import clsx from "clsx"
import { OnProduct } from "../OnProduct"
import { ReloadOutlined } from "@ant-design/icons"
import { MatchToPlay } from "../Match/MatchToPlay"

type Props = {
  product?: TProduct
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export default function Aside({ product, open, setOpen }: Props) {
  const { t } = useTranslation()
  const [searchMatches, setSearchMatches] = useState<TMatch[] | undefined>()

  const {
    data: matches,
    refetch,
    isFetching,
  } = useQuery({
    queryFn: async () => GetMatchesByProductId({ id: product!.id! }),
    queryKey: ["getMatchesByProductId" + product?.id],
    enabled: !!product?.id,
  })

  useEffect(() => {
    if (matches) setSearchMatches(matches)
  }, [matches])

  const matchesPlayed =
    matches?.reduce((acc, curr) => {
      return curr.played ? acc + 1 : acc
    }, 0) ?? 0

  const SearchMatches = useCallback(
    (value: string) => {
      if (value) {
        const filteredMatches = matches?.filter((match) => {
          return match.nickTwitch.toLowerCase().includes(value.toLowerCase())
        })
        setSearchMatches(filteredMatches)
      } else {
        setSearchMatches(matches)
      }
    },
    [matches],
  )

  return (
    <div
      className={clsx(
        "right-0 flex w-full max-w-[24rem] flex-col bg-primary p-0 transition-all md:static",
        open ? "animate-slide-left-in" : "animate-slide-right-out",
      )}
    >
      <div className="relative flex w-full flex-col bg-tertiary p-0">
        <div
          className="shadow-orange-[#FF7A00] absolute z-10 m-8 h-6 w-6 cursor-pointer text-[#FF7A00] bg-blend-color-burn transition-all hover:scale-125"
          onClick={() => setOpen(false)}
        >
          <ArrowCircleRightTwoToneIcon />
        </div>
        <OnProduct />
        <div className="flex items-center justify-between px-4">
          <span className="w-full text-center text-base font-bold uppercase text-orange-500">
            {t("Aside.SoldMatches")}
          </span>
          <Button className="ml-2 h-8 w-14" onClick={() => refetch()}>
            <ReloadOutlined
              className={clsx(isFetching && "animate-spin text-orange-500")}
            />
          </Button>
        </div>
        <div className="my-4 flex items-center gap-x-2 px-4">
          <div className="flex w-full items-center rounded-sm bg-[#232325]">
            <Input
              prefix={<Search className="text-neutral" />}
              placeholder={t("Aside.SearchUsername")}
              className="h-8 rounded-none border-0 px-1 py-0"
              onChange={(e) => SearchMatches(e.target.value)}
            />
          </div>
          <span className="text-md m-0 items-center text-nowrap font-semibold text-neutral">
            {t("Resume.players", {
              players: matchesPlayed,
              total: matches?.length ?? 0,
            })}
          </span>
        </div>
        <div className="bg-secondary px-4">
          {searchMatches ? (
            searchMatches.map((match, index) => (
              <MatchToPlay
                key={match.id}
                product_id={product?.id!}
                match={match}
                order={index}
              />
            ))
          ) : (
            <Spin />
          )}
        </div>
      </div>
    </div>
  )
}
