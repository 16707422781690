import { CheckOutlined, InfoCircleOutlined } from "@ant-design/icons"
import { ComputerOutlined, FileCopyOutlined } from "@mui/icons-material"
import { Button, Flex, Modal } from "antd"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { useTranslation } from "react-i18next"
import { TMatch } from "../../types"
import { XTwitchIcon } from "../Icons"
import { useState } from "react"
import Whisper from "../Whisper"

type DuoClientInviteModalProps = {
  isModalOpen: boolean
  handleOk: () => void
  handleCancel: () => void
  clientMatchData: Partial<TMatch>
}
export const MatchInviteModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  clientMatchData,
}: DuoClientInviteModalProps) => {
  const { t } = useTranslation()
  const [openWhisper, setOpenWhisper] = useState(false)

  return (
    <Modal
      title={t("Matches.Modal.Invite.Invite")}
      open={isModalOpen}
      styles={{
        header: {
          backgroundColor: "#1B1B1B",
        },
        content: {
          backgroundColor: "#1B1B1B",
          padding: "1.5rem",
        },
      }}
      onCancel={handleCancel}
      footer={null}
      cancelText={false}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          gap: "2rem",
          marginBottom: "2rem",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            backgroundColor: "#141414",
            width: "100%",
            height: "fit-content",
            borderRadius: "4px",
            alignItems: "center",
          }}
        >
          <XTwitchIcon
            style={{
              marginLeft: "1rem",
              width: "3rem",
              height: "3rem",
              borderRadius: "10%",
              backgroundColor: "#9146FF",
              padding: "4px",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "1rem",
            }}
          >
            <p
              style={{
                fontSize: "12px",
              }}
            >
              {t("Matches.Modal.Invite.NicknameTwitch")}
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                marginTop: "-1rem",
              }}
            >
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginRight: "1rem",
                }}
              >
                {clientMatchData.nickTwitch}
              </p>
              <CopyToClipboard text={clientMatchData.nickTwitch || ""}>
                <FileCopyOutlined
                  style={{
                    cursor: "pointer",
                    color: "#FF7A00",
                  }}
                />
              </CopyToClipboard>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            backgroundColor: "#141414",
            width: "100%",
            height: "fit-content",
            borderRadius: "4px",
            alignItems: "center",
          }}
        >
          <ComputerOutlined
            style={{
              marginLeft: "1rem",
              width: "3rem",
              height: "3rem",
              borderRadius: "10%",
              backgroundColor: "#1c1c1c",
              padding: "4px",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "1rem",
            }}
          >
            <p
              style={{
                fontSize: "12px",
              }}
            >
              {t("Matches.Modal.Invite.Username")}
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                marginTop: "-1rem",
              }}
            >
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginRight: "1rem",
                }}
              >
                {clientMatchData.tag}
              </p>
              <CopyToClipboard text={clientMatchData.tag || ""}>
                <FileCopyOutlined
                  style={{
                    cursor: "pointer",
                    color: "#FF7A00",
                  }}
                />
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </div>
      {openWhisper && <Whisper nickName={clientMatchData.nickTwitch!} />}
      <Flex
        align="center"
        gap="0.5rem"
        style={{
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      >
        <InfoCircleOutlined />
        <p
          style={{
            fontSize: "14px",
            color: "#7D7D7D",
            margin: "0",
          }}
        >
          {t("Matches.Modal.Invite.CheckDailyLimit")}
        </p>
      </Flex>
      <Flex justify="space-between">
        <Button
          onClick={() => setOpenWhisper(!openWhisper)}
          type="primary"
          style={{
            textTransform: "uppercase",
            fontWeight: "bold",
            backgroundColor: "transparent",
            color: "#FF7A00",
            boxShadow: "initial",
          }}
        >
          {openWhisper
            ? t("Whisper.Cancel")
            : t("Matches.Modal.Invite.SendWhisper")}
        </Button>
        <Button
          type="primary"
          onClick={handleOk}
          style={{
            textTransform: "uppercase",
            fontWeight: "bold",
            backgroundColor: "transparent",
            boxShadow: "initial",
          }}
        >
          {t("Matches.Modal.Invite.MatchPlayed")}
          <CheckOutlined
            size={24}
            style={{
              borderRadius: "2px",
              padding: "1px",
              color: "black",
              backgroundColor: "green",
            }}
          />
        </Button>
      </Flex>
    </Modal>
  )
}
