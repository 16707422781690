export function CheckOutlinedIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={52}
      height={52}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={52} height={52} rx={8} fill="#0E2204" />
      <path
        d="M38.5 15.938h-2.185a.995.995 0 00-.784.38L22.646 32.642l-6.178-7.828a.999.999 0 00-.784-.382h-2.185a.25.25 0 00-.197.403l8.56 10.844c.4.506 1.169.506 1.572 0l15.262-19.34a.248.248 0 00-.197-.4z"
        fill="#49AA19"
      />
    </svg>
  )
}
