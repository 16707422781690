import { zodResolver } from "@hookform/resolvers/zod"
import { Button, Form, message } from "antd"
import TextArea from "antd/es/input/TextArea"
import { useForm } from "react-hook-form"
import { FormItem } from "react-hook-form-antd"
import { TWhisper, WhisperSchema } from "../../types"
import { PostWhisper } from "../../services/request/Whisper"
import { GetAccountByNickname } from "../../services/request/Account"
import { useQuery } from "@tanstack/react-query"
import { useState } from "react"
import { ErrorWhisper } from "./ErrorWhisper"
import { useTranslation } from "react-i18next"
import { useUSer } from "../../hook/useUser"

type Props = {
  nickName: string
}
export default function Whisper({ nickName }: Props) {
  const { t } = useTranslation()
  const [messageApi, contextHolder] = message.useMessage()
  // const [isLoading, setIsLoading] = useState(false)
  const [sendError, setSendError] = useState(false)
  const { data: user } = useUSer()
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<TWhisper>({
    resolver: zodResolver(WhisperSchema),
  })
  const { data } = useQuery({
    queryKey: ["inviteUser" + nickName],
    queryFn: () => GetAccountByNickname(nickName),
    enabled: !!nickName,
  })
  const handleSend = async (whisper: TWhisper) => {
    try {
      // setIsLoading(true)
      await PostWhisper({
        message: whisper.message,
        client_account_id: data?.id!,
      })
      // setIsLoading(false)
      reset()
      messageApi.success(t("Whisper.Sent"))
    } catch (error: any) {
      setSendError(true)
    }
  }
  return (
    <>
      {contextHolder}
      {sendError ? (
        <ErrorWhisper closeError={() => setSendError(false)} />
      ) : (
        <Form
          style={{
            display: "flex",
            width: "100%",
            alignItems: "flex-end",
            flexDirection: "column",
          }}
          layout="vertical"
          onFinish={handleSubmit(handleSend)}
        >
          <FormItem
            control={control}
            name="message"
            label={t("Whisper.Message")}
            labelCol={{ style: { fontWeight: "bold" } }}
            style={{ marginTop: 2, width: "100%" }}
          >
            <TextArea
              defaultValue={user?.whisper_message}
              rows={4}
              maxLength={200}
              placeholder={t("Whisper.PlaceHolder")}
            />
          </FormItem>
          <Button
            disabled={isSubmitting}
            loading={isSubmitting}
            type="primary"
            htmlType="submit"
            style={{
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            {t("Whisper.Send")}
          </Button>
        </Form>
      )}
    </>
  )
}
