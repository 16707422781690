export function PadlockIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={20}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.5 8.875h-1.594v-5.25a3 3 0 00-3-3H6.094a3 3 0 00-3 3v5.25H1.5a.75.75 0 00-.75.75v9c0 .415.335.75.75.75h15a.75.75 0 00.75-.75v-9a.75.75 0 00-.75-.75zM4.781 3.625c0-.724.589-1.313 1.313-1.313h5.812c.724 0 1.313.589 1.313 1.313v5.25H4.78v-5.25zm10.782 14.063H2.437v-7.125h13.126v7.124zm-7.22-3.258v1.242c0 .103.085.187.188.187h.938a.188.188 0 00.187-.187V14.43a1.125 1.125 0 10-1.312 0z"
        fill="#49AA19"
      />
    </svg>
  )
}
