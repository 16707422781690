import { useStripePromise } from "../../hook/useStripePromise"
import { ErrorScreen } from "../../components/ErrorScreen"
import { Link, useLocation } from "react-router-dom"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"
import { PaymentVerify } from "./PaymentVerify"
import PaymentSteps from "../../components/Payment/steps/PaymentSteps"
import { PaymentOrderCard } from "../../components/Payment/PaymentOrderCard"
import { Helmet } from "react-helmet"

export function Payment() {
  const { t } = useTranslation()
  const location = useLocation()
  const { data: stripePromise } = useStripePromise()
  const { talent, product, isLoading, paymentError } = PaymentVerify()

  if (paymentError || isLoading)
    return <ErrorScreen isLoading={isLoading}>{paymentError}</ErrorScreen>

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Helmet.payment.title")}</title>
        <meta name="description" content={t("Helmet.payment.description")} />
        <link rel="canonical" href={origin + location.pathname} />
      </Helmet>
      <div className="mx-auto my-10 flex h-full flex-col">
        <Link
          to={"/" + talent!.name}
          className="mb-10 flex gap-x-2 px-4 py-1 font-bold uppercase text-orange-500"
        >
          <ArrowLeftOutlined />
          {t("ToGoBack")}
        </Link>
        <div className="flex overflow-x-scroll md:gap-10 md:overflow-x-auto">
          <div className="w-screen min-w-max md:w-full">
            <PaymentOrderCard product={product} talentNickName={talent?.name} />
          </div>
          <div className="w-screen min-w-max md:w-full">
            <PaymentSteps
              product={product}
              talent={talent}
              stripePromise={stripePromise}
            />
          </div>
        </div>
      </div>
    </>
  )
}
