import { useTalent } from "../../hook/useTalent"
import { useQuery } from "@tanstack/react-query"
import { GetEnabledProductsByAccount } from "../../services/request/Product"
import { MatchesPanel } from "../../views/MatchesPanel/MatchesPanel"
import { useTranslation } from "react-i18next"
import IsOnline from "../../components/IsOnline"
import { useNavigate } from "react-router"
import { UserPicture } from "../../components/UserPicture"
import CardProductSkeleton from "../../components/Skeloton"
import { ChatBubble, RightSquareOutlined } from "../../components/Icons"
import { ProductClientCard } from "../../components/Product/ProductClientCard"
import { useLocation } from "react-router-dom"
import { Helmet } from "react-helmet"
import { Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"
import SentimentVeryDissatisfiedOutlinedIcon from "@mui/icons-material/SentimentVeryDissatisfiedOutlined"
export function TalentPage() {
  const { data: talent, isLoading: talentIsLoading } = useTalent()
  const { t } = useTranslation()
  const navigation = useNavigate()
  const location = useLocation()

  const {
    data: products,
    isLoading: productsIsLoading,
    refetch,
  } = useQuery({
    queryKey: ["getEnabledProducts"],
    queryFn: () => GetEnabledProductsByAccount(talent!.id),
    enabled: !!talent,
  })

  if (!talent && !talentIsLoading)
    return (
      <div className="flex items-center justify-center gap-2">
        <SentimentVeryDissatisfiedOutlinedIcon fontSize="large" />
        <h1 className="font-bold uppercase">404 {t("info.TalentNotFound")}</h1>
      </div>
    )

  if (talentIsLoading)
    return (
      <div className="flex h-[17rem] items-center justify-center">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    )

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{talent?.name}</title>
        <meta name="description" content={talent?.profile_description} />
        <link rel="canonical" href={origin + location.pathname} />
      </Helmet>
      <div className="mx-auto mb-10 mt-10 flex flex-col gap-y-8 px-4 lg:w-[70vw] lg:px-0">
        <div className="flex flex-col gap-y-4 lg:gap-y-8">
          <div className="flex flex-col items-center border border-solid border-tertiary bg-secondary p-4 lg:flex-row lg:items-start lg:justify-between">
            <div className="flex items-center lg:items-start">
              <UserPicture alt={talent?.name} src={talent?.avatar} />
              <div className="ml-4 lg:ml-8">
                <h1 className="text-xl lg:text-2xl">@{talent?.name}</h1>
                <div className="mt-2 flex items-center lg:mt-4">
                  <IsOnline
                    title={t("live")}
                    online={!!talent?.current_match}
                  />
                </div>
                <p className="mt-2 text-sm leading-6 text-neutral">
                  {talent?.profile_description}
                </p>
              </div>
            </div>
          </div>
          <div className="flex min-h-[20rem] w-full flex-col items-center justify-center border border-solid border-tertiary lg:min-h-[30rem]">
            <MatchesPanel
              title={
                <div className="flex w-full items-center justify-between">
                  <p className="m-0 flex items-center gap-x-4 text-lg font-bold lg:text-[1.625rem]">
                    <RightSquareOutlined />
                    {t("Products")}
                  </p>
                </div>
              }
            >
              <div className="flex flex-wrap justify-center gap-4 md:justify-start lg:gap-6">
                {productsIsLoading ? (
                  Array.from({ length: 5 }).map((_, index) => (
                    <CardProductSkeleton key={index} />
                  ))
                ) : products && products.length > 0 ? (
                  products.map((product) => (
                    <ProductClientCard
                      refetch={refetch}
                      product={product}
                      key={product.id}
                      execute={() => {
                        navigation(
                          `/payment?talent=${talent!.name}&product_id=${product.id}`,
                        )
                      }}
                    />
                  ))
                ) : (
                  <div className="flex w-full flex-col items-center justify-center gap-y-4">
                    <ChatBubble />
                    <span className="font-semibold text-neutral">
                      {t("TalentHasNoMatches")}
                    </span>
                    <p className="m-0 text-neutral">
                      {t("TalentHasNoMatchesInMoment")}
                    </p>
                  </div>
                )}
              </div>
            </MatchesPanel>
          </div>
        </div>
      </div>
    </>
  )
}
