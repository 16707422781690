import { Layout } from "antd"
import { ReactNode, useState } from "react"
import { Sidebar } from "./Sidebar"
import { HeaderView } from "./Header"
import MethodsFooter from "../footers/MethodsFooter"
const { Content } = Layout

type Props = {
  children: ReactNode
  onSidebar?: boolean
}

export const LayoutView = ({ children, onSidebar }: Props) => {
  const [collapsed, setCollapsed] = useState<boolean>(false)
  return (
    <Layout className="h-screen">
      <HeaderView collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout>
        {onSidebar && (
          <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
        )}
        <Content className="overflow-y-auto">
          <div className="pl-6 pt-6">{children}</div>
          <MethodsFooter ClassName="mt-10" paymentMethods />
        </Content>
      </Layout>
    </Layout>
  )
}
