import { CheckOutlined, CloseOutlined } from "@mui/icons-material"
import { Avatar } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { Button } from "antd"
import Paragraph from "antd/es/typography/Paragraph"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { queryClient } from "../../lib/reactQuery"
import { GetAccountPerformance } from "../../services/request/Account"
import { UpdateMatchToPlay } from "../../services/request/Matches"
import { TAccountPerformance, TMatch } from "../../types"
import { MatchInviteModal } from "./MatchInviteModal"
import { MatchUninvitedModal } from "./MatchUninviteModal"

interface MatchToPlayProps {
  match: TMatch
  order: number
  product_id: string
}

export const MatchToPlay: React.FC<MatchToPlayProps> = ({
  match,
  order,
  product_id,
}) => {
  const [isModalInviteMatchOpen, setIsModalInviteMatchOpen] = useState(false)
  const [isModalUninviteMatchOpen, setIsModalUninviteMatchOpen] =
    useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const { t } = useTranslation()

  const { mutateAsync: updateMatchmatchMutate } = useMutation({
    mutationFn: UpdateMatchToPlay,
    onSuccess: () => {
      const cached = queryClient.getQueryData<TMatch[]>([
        "getMatchesByProductId" + product_id,
      ])
      queryClient.setQueryData<TMatch[]>(
        ["getMatchesByProductId" + product_id],
        cached?.map((client) => {
          if (client.id === match.id) {
            return {
              ...client,
              played: !client.played,
            }
          }
          return client
        }),
      )
    },
  })

  const { mutateAsync: updatePerformanceMutate } = useMutation({
    mutationFn: GetAccountPerformance,
    onSuccess: () => {
      const cached = queryClient.getQueryData<TAccountPerformance>([
        "getAccountPerformance",
      ])
      if (cached) {
        queryClient.setQueryData<TAccountPerformance>(
          ["getAccountPerformance"],
          {
            ...cached,
            matches_played: match.played
              ? cached.matches_played + 1
              : cached.matches_played - 1,
          },
        )
      }
    },
  })

  const handleInviteMatchOk = () => {
    updateMatchmatchMutate({ id: match.id, status: true })
    setIsModalInviteMatchOpen(false)
    updatePerformanceMutate()
  }

  const handleCancelInviteMatch = () => {
    updateMatchmatchMutate({ id: match.id, status: false })
    setIsModalUninviteMatchOpen(false)
    updatePerformanceMutate()
  }

  return (
    <div className="max-w-34rem mt-4 flex flex-row items-center justify-between gap-4">
      <div className="flex flex-row items-center justify-center gap-4 align-middle">
        <p className="m-1 flex h-7 w-7 items-center justify-center rounded-full bg-tertiary text-xs font-semibold text-white">
          {order + 1}
        </p>
        <Avatar
          className="h-7 w-7 whitespace-nowrap rounded-lg bg-tertiary text-gray-500"
          alt={match.nickTwitch}
        />
        <Paragraph className="mb-0 text-xs font-bold text-gray-400">
          {match.nickTwitch}
        </Paragraph>
      </div>

      <div className="flex flex-row items-center justify-between gap-2 align-middle">
        {match.played ? (
          <Button
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className="flex items-center gap-x-2 text-xs font-bold"
            onClick={() => setIsModalUninviteMatchOpen(true)}
          >
            <Paragraph className="mb-0 whitespace-nowrap text-xs text-gray-400">
              Partida jogada
            </Paragraph>
            {isHovered ? (
              <CloseOutlined style={{ color: "red" }} />
            ) : (
              <CheckOutlined style={{ color: "green" }} />
            )}
          </Button>
        ) : (
          <Button
            type="text"
            className="text-xs font-bold uppercase text-orange-300"
            onClick={() => setIsModalInviteMatchOpen(true)}
          >
            {t("ToInvite")}
          </Button>
        )}
      </div>
      <MatchInviteModal
        isModalOpen={isModalInviteMatchOpen}
        handleOk={handleInviteMatchOk}
        handleCancel={() => setIsModalInviteMatchOpen(false)}
        clientMatchData={match}
      />
      <MatchUninvitedModal
        isModalOpen={isModalUninviteMatchOpen}
        handleOk={handleCancelInviteMatch}
        handleCancel={() => setIsModalUninviteMatchOpen(false)}
      />
    </div>
  )
}
