import { Layout } from "antd"
import { ReactNode } from "react"
import { HeaderView } from "./Header"
import MethodsFooter from "../footers/MethodsFooter"
const { Content } = Layout

type Props = {
  children: ReactNode
}

export const LayoutViewSecondary = ({ children }: Props) => {
  return (
    <Layout className="min-h-screen">
      <HeaderView />
      <Content className="flex h-full justify-center overflow-y-auto">
        {children}
      </Content>
      <MethodsFooter paymentMethods />
    </Layout>
  )
}
