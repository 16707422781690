import {
  SynthesizeSpeechCommand,
  SynthesizeSpeechInput,
} from "@aws-sdk/client-polly"
import { polly } from "../../services/aws/polly"
type props = {
  client_name: string
  talent_name: string
  category: string
}

export const Speak = async ({
  client_name,
  talent_name,
  category,
}: props): Promise<void> => {
  const audio = new Audio()
  const input: SynthesizeSpeechInput = {
    Engine: "standard",
    LanguageCode: "pt-BR",
    OutputFormat: "mp3",
    Text: `${client_name} comprou uma duoplay de ${category} com ${talent_name}`,
    TextType: "text",
    VoiceId: "Ricardo",
  }

  const command = new SynthesizeSpeechCommand(input)
  const response = await polly.send(command)
  if (response.AudioStream) {
    const audioArrayBuffer = await response.AudioStream!.transformToByteArray()
    const audioBlob = new Blob([audioArrayBuffer], { type: "audio/mpeg" })
    audio.autoplay = true
    audio.src = URL.createObjectURL(audioBlob)
    audio.load()
    audio.play()
  }
}
