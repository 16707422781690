import { privateApi, publicApi } from "../../api"

export async function PostObsVerify(content: { account_id: string }): Promise<void> {
  const res = await privateApi.post("/obs-verify", content)
  return res.data
}

export async function PatchObsVerify(content: { id: string }): Promise<void> {
  const res = await publicApi.patch("/obs-verify", content)
  return res.data
}