import { useEffect, useState } from "react"
import { Button, Input } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import { GetAllAccounts } from "../../services/request/Account"
import { Loading } from "../../components/Loading"
import { Tooltip } from "antd"

export type TTalent = {
  id: string
  name: string
  avatar: string
  isTalent: boolean
  profile_description?: string | undefined
}

export const Talents = () => {
  const [talents, setTalents] = useState<TTalent[]>([])
  const [searchTalent, setSearchTalent] = useState<string>("")
  const [filteredTalents, setFilteredTalents] = useState<TTalent[]>([])
  const { t } = useTranslation()

  const handleSearchTalent = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTalent(e.target.value)
    if (e.target.value === "") {
      setFilteredTalents(talents)
    } else {
      setFilteredTalents(
        talents.filter((talent) =>
          talent.name.toLowerCase().includes(e.target.value.toLowerCase()),
        ),
      )
    }
  }

  const { data, isLoading } = useQuery({
    queryKey: ["talents"],
    queryFn: () => GetAllAccounts(),
    staleTime: 1000 * 60 * 5,
    refetchInterval: 1000 * 60 * 5,
  })

  useEffect(() => {
    if (data) {
      setTalents(data)
    }
  }, [data])

  useEffect(() => {
    setFilteredTalents(talents)
  }, [talents])

  return (
    <div className="flex flex-col items-center justify-center gap-6">
      <div
        className="flex flex-col items-center justify-center gap-4"
        style={{ width: "100%" }}
      >
        <h1 className="text-4xl font-bold">{t("TalentsOnline.title")}</h1>
        <Input
          placeholder={t("TalentsOnline.search")}
          value={searchTalent}
          onChange={handleSearchTalent}
          className="flex justify-center border-2 border-orange-500 bg-gray-700 p-2 align-middle text-white"
          style={{
            borderRadius: "8px",
            minWidth: "70vw",
          }}
        />
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="mb-10 flex flex-wrap justify-center gap-4">
          {filteredTalents.map((talent) => (
            <div
              key={talent.id}
              className="max-w-42 flex flex-col items-center justify-center rounded-lg bg-gray-900 p-4 shadow-md hover:bg-gray-800"
              style={{ width: "230px" }}
            >
              <img
                src={talent.avatar}
                alt={talent.name}
                className=".125rem solid green h-32 w-32 rounded-full"
              />
              <h2 className="text-center text-lg font-bold">{talent.name}</h2>
              <Tooltip title={talent.profile_description}>
                <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-center text-sm text-gray-500">
                  {talent.profile_description}
                </p>
              </Tooltip>
              <div className="flex justify-center">
                <Button
                  className="bg-orange-500 text-base font-bold uppercase text-black"
                  onClick={() => window.open("/" + talent.name, "_blank")}
                >
                  {t("TalentsOnline.viewProfile")}
                </Button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
