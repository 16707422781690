import { Layout } from "antd"
import { ReactNode } from "react"
import { HeaderView } from "./Header"
import MethodsFooter from "../footers/MethodsFooter"
const { Content } = Layout

type Props = {
  children: ReactNode
}

export const LayoutPayment = ({ children }: Props) => {
  return (
    <Layout className="min-h-screen">
      <HeaderView />
      <Content className="flex h-full overflow-y-auto">{children}</Content>
      <MethodsFooter  />
    </Layout>
  )
}
