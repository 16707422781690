import { useTranslation } from "react-i18next"
import { TProduct } from "../../types"
import { FormatCurrency } from "../../utils/currencyHelper"
import { formatImage } from "../../utils/imageHelper"

type Props = {
  product?: TProduct
  talentNickName?: string
}
type PropsColumns = {
  key: string
  value: string
}
const AVATAR_IMAGE_SIZE = {
  width: 360,
  height: 345,
}
export function PaymentOrderCard({ product, talentNickName }: Props) {
  const { t } = useTranslation()

  const Columns: Array<PropsColumns> = [
    {
      key: t("CheckOut.CardOrder.category"),
      value: product?.name || "NA",
    },
    {
      key: t("CheckOut.CardOrder.PlayWith"),
      value: talentNickName!,
    },
    {
      key: t("CheckOut.CardOrder.title"),
      value: product?.title || "NA",
    },
    {
      key: t("CheckOut.CardOrder.quantity"),
      value: t("Payment.Success.vacancy", { quantity: 1 }),
    },
    {
      key: t("CheckOut.CardOrder.value"),
      value: FormatCurrency({ value: product!.value }),
    },
  ]

  return (
    <div className="h-full w-[20.875rem] bg-secondary px-10 py-4">
      <h1 className="m-0 mb-4 text-xl font-semibold">
        {t("CheckOut.YouAreBuying")}
      </h1>
      <div className="rounded-sm border border-solid border-tertiary">
        <img
          height={AVATAR_IMAGE_SIZE.height}
          width={AVATAR_IMAGE_SIZE.width}
          src={formatImage(
            product?.avatar!,
            AVATAR_IMAGE_SIZE.width,
            AVATAR_IMAGE_SIZE.height,
          )}
          alt={`Avatar of ${product?.name}`}
          className="h-[13rem] w-[15.875rem] rounded-t-sm bg-top object-cover object-top"
        />
        <div className="flex flex-col gap-y-4 px-4 py-4 text-neutral">
          {Columns.map((column, index) => (
            <div
              key={index}
              className="flex h-[1.375rem] items-center justify-between space-x-4"
            >
              <span className="m-0 flex text-nowrap font-bold">
                {column.key}
              </span>
              <span className="truncate">{column.value}</span>
            </div>
          ))}
        </div>
        <div className="flex h-14 items-center justify-between gap-x-4 border-t border-solid border-tertiary px-4 text-xl font-semibold text-white">
          <span>{t("CheckOut.CardOrder.total")}</span>
          <span>
            {FormatCurrency({
              value: product!.value,
              currency: product?.currency,
            })}
          </span>
        </div>
      </div>
    </div>
  )
}
