import { Button, Layout } from "antd"
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore"
import { useEffect, useRef, useState } from "react"
import { useTalent } from "../../hook/useTalent"
import { db } from "../../services/firebase/db"
import { TOrder } from "../../types"
import { Speak } from "./start-speak"
import clsx from "clsx"
import { ErrorScreen } from "../../components/ErrorScreen"
import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet"
import { useLocation } from "react-router-dom"
import { ObsVerify } from "./obsVerify"
import { ObsMessage } from "./obsMessage"

export default function ObsPage() {
  const [orders, setOrders] = useState<TOrder[]>([])
  const [orderPosition, setOrderPosition] = useState(1)
  const [currentOrder, setCurrentOrder] = useState<TOrder>()
  const [observe, setObserve] = useState(false)
  const [over, setOver] = useState(false)
  const location = useLocation()
  const [active, setActive] = useState(false)
  const { data: talent, isLoading } = useTalent()
  const ref_button_start = useRef<HTMLButtonElement>(null)
  const { t } = useTranslation()

  useEffect(() => {
    if (ref_button_start.current) {
      ref_button_start.current.click()
    }
    if (!observe) return
    const q = query(
      collection(db, "orders"),
      where("talent_id", "==", talent?.id || ""),
      orderBy("createdAt", "desc"),
      limit(1),
    )

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setOrders((prev) => {
          const orderExist = prev.some((order) => order.id === doc.id)
          if (orderExist) {
            return prev
          } else {
            return [...prev, { ...doc.data(), id: doc.id }] as TOrder[]
          }
        })
      })
    })
    return () => unsubscribe()
  }, [talent, observe])

  useEffect(() => {
    const interval = setInterval(() => {
      releaseNextOrder()
    }, 8000)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, orderPosition])

  const releaseNextOrder = async () => {
    if (orderPosition < orders.length && orders.length > 0) {
      await Speak({
        client_name: orders[orderPosition].client_account.name,
        talent_name: talent?.name!,
        category: orders[orderPosition].item.name,
      })
      setCurrentOrder(orders[orderPosition])
      setActive(true)
      setTimeout(() => {
        setActive(false)
      }, 10000)
      setOrderPosition((prevIndex) => prevIndex + 1)
    }
  }

  if (!talent)
    return (
      <ErrorScreen isLoading={isLoading}>
        {t("info.TalentNotFound")}
      </ErrorScreen>
    )
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Helmet.obs.title")}</title>
        <meta name="description" content={t("Helmet.obs.description")} />
        <link rel="canonical" href={origin + location.pathname} />
      </Helmet>
      <Layout
        className="flex h-screen w-screen flex-col items-center justify-center bg-transparent transition-all duration-500 hover:bg-primary"
        onMouseEnter={() => setOver(true)}
        onMouseLeave={() => setOver(false)}
      >
        <h1
          className={clsx(
            "text-6xl font-bold uppercase text-orange-500 transition-all duration-1000",
            over ? "flex" : "hidden",
          )}
        >
          DuoToPlay
        </h1>
        {!observe && (
          <Button
            ref={ref_button_start}
            className={clsx(over ? "flex" : "hidden")}
            onClick={() => setObserve(true)}
          >
            Observer
          </Button>
        )}

          <ObsMessage
            active={active}
            client_name={currentOrder?.client_account.name!}
            talent_name={talent!.name}
            category={currentOrder?.item.name!}
          />
          <ObsVerify />
      </Layout>
    </>
  )
}
