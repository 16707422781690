import { zodResolver } from "@hookform/resolvers/zod"
import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { FormItem } from "react-hook-form-antd"
import { useTranslation } from "react-i18next"

import {
  Button,
  Flex,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  message,
} from "antd"

import { Modal } from "../../components/ui/Modal/Modal"
import { TCreateProduct, createProductSchema } from "../../types"
import { PostProduct } from "../../services/request/Product"
import { useQuery } from "@tanstack/react-query"
import { GetCategories } from "../../services/request/Category"
import { Platforms } from "../../constants"


type MatchModalCreateProps = {
  open: boolean
  handleClose(): void
  refresh(): void
}

export const MatchModalCreate = (props: MatchModalCreateProps) => {
  const { t } = useTranslation()
  const [charactersTitle, setCharactersTitle] = useState(0)
  const [charactersDescription, setCharactersDescription] = useState(0)

  const { open, handleClose, refresh } = props
  const [messageApi, contextHolder] = message.useMessage()
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isSubmitting, isValid },
  } = useForm<TCreateProduct>({
    resolver: zodResolver(createProductSchema(t)),
  })
  const { data: categories } = useQuery({
    queryKey: ["getCategories"],
    queryFn: GetCategories,
  })
  const handleSave = async (match: TCreateProduct) => {
    try {
      await PostProduct({
        ...match,
      })
      messageApi.success(t("createMatch.created"))
      refresh()
      reset()
      handleClose()
    } catch (error) {
      messageApi.error(t("createMatch.errorToCreate"))
    }
  }

  return (
    <>
      {contextHolder}

      <Modal open={open} handleClose={handleClose} title={t("Duo.create")}>
        <>
          <Typography.Paragraph style={{ paddingTop: 6, color: "#fff" }}>
            {t("createMatch.descriptionHeader")}
          </Typography.Paragraph>
          <Form preserve layout="vertical" onFinish={handleSubmit(handleSave)}>
            <FormItem
              required
              control={control}
              name="title"
              label={t("createMatch.name")}
              style={{ marginBottom: 16 }}
              labelCol={{ style: { fontWeight: "bold" } }}
            >
              <Input
                onChange={(e) => {
                  setCharactersTitle(e.target.value.length)
                }}
                autoComplete="off"
                style={{ borderRadius: 2, backgroundColor: "#141414" }}
                maxLength={60}
                placeholder={t("createMatch.placeHolder.title")}
              />
              <Flex
                justify="right"
                style={{
                  fontSize: 12,
                  color: "#8C8C8C",
                  marginTop: 4,
                  textTransform: "lowercase",
                }}
              >
                {charactersTitle}/60 {t("createMatch.characters")}
              </Flex>
            </FormItem>
            <FormItem
              required
              control={control}
              name="description"
              label={t("createMatch.description")}
              labelCol={{ style: { fontWeight: "bold" } }}
              style={{ marginBottom: 16 }}
            >
              <Input.TextArea
                autoComplete="off"
                style={{
                  height: 80,
                  resize: "none",
                  borderRadius: 2,
                  backgroundColor: "#141414",
                }}
                placeholder={t("createMatch.placeHolder.description")}
                onChange={(e) => {
                  setCharactersDescription(e.target.value.length)
                }}
                maxLength={124}
              />
              <Flex
                justify="right"
                style={{
                  fontSize: 12,
                  color: "#8C8C8C",
                  marginTop: 4,
                  textTransform: "lowercase",
                }}
              >
                {charactersDescription}/124 {t("createMatch.characters")}
              </Flex>
            </FormItem>
            <Controller
              control={control}
              name="product_description_id"
              render={({ field: { onChange, value } }) => {
                if (!categories) return <></>
                return (
                  <FormItem
                    required
                    control={control}
                    style={{ marginBottom: 16 }}
                    name="product_description_id"
                    labelCol={{ style: { fontWeight: "bold" } }}
                    label={t("createMatch.category")}
                  >
                    <Select
                      style={{ borderRadius: 2, backgroundColor: "#141414" }}
                      onChange={onChange}
                      value={value}
                      showSearch
                      placeholder={t(
                        "createMatch.placeHolder.product_description_id",
                      )}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={categories.map((category) => ({
                        label: category.description,
                        value: category.id,
                      }))}
                    />
                  </FormItem>
                )
              }}
            />
            <Controller
              control={control}
              name="platform"
              render={({ field: { onChange, value } }) => {
                return (
                  <FormItem
                    control={control}
                    name="platform"
                    labelCol={{ style: { fontWeight: "bold" } }}
                    label={t("createMatch.platform")}
                  >
                    <Select
                      style={{ borderRadius: 2 }}
                      onChange={onChange}
                      value={value}
                      showSearch
                      placeholder={t("createMatch.placeHolder.platform")}
                      options={[
                        ...Platforms.map((platform) => ({
                          label: platform.label,
                          value: platform.value,
                        })),
                        {
                          label: t("createMatch.otherPlatform"),
                          value: "other",
                        },
                      ]}
                    />
                  </FormItem>
                )
              }}
            />
            <Flex justify="space-between" gap={24}>
              <Controller
                control={control}
                name="device"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FormItem
                    control={control}
                    name="device"
                    label={t("createMatch.device")}
                    style={{ width: "100%", marginBottom: 0 }}
                    labelCol={{ style: { fontWeight: "bold" } }}
                  >
                    <Select
                      onChange={onChange}
                      value={value}
                      style={{ borderRadius: 2 }}
                      placeholder={t("createMatch.placeHolder.device")}
                    >
                      <Select.Option value="PC">PC</Select.Option>
                      <Select.Option value="PS4">PS4</Select.Option>
                      <Select.Option value="PS5">PS5</Select.Option>
                      <Select.Option value="Xbox One">Xbox One</Select.Option>
                      <Select.Option value="Xbox Series S/X">
                        Xbox Series S/X
                      </Select.Option>
                      <Select.Option value="Nintento">Nintento</Select.Option>
                      <Select.Option value="Android">Android</Select.Option>
                      <Select.Option value="iPhone/iPad">
                        iPhone/iPad
                      </Select.Option>
                      <Select.Option value="other">
                        {t("createMatch.otherDevice")}
                      </Select.Option>
                    </Select>
                  </FormItem>
                )}
              />
              <Controller
                control={control}
                name="region"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FormItem
                    name="region"
                    control={control}
                    label={t("createMatch.region")}
                    style={{ width: "100%", marginBottom: 16 }}
                    labelCol={{ style: { fontWeight: "bold" } }}
                  >
                    <Select
                      onChange={onChange}
                      value={value}
                      placeholder={t("createMatch.placeHolder.region")}
                    >
                      <Select.Option value="EU">
                        {t("Region.Europe")}
                      </Select.Option>
                      <Select.Option value="NA">
                        {t("Region.NorthAmerica")}
                      </Select.Option>
                      <Select.Option value="SU">
                        {t("Region.SouthAmerica")}
                      </Select.Option>
                      <Select.Option value="AS">
                        {t("Region.Asia")}
                      </Select.Option>
                      <Select.Option value="OC">
                        {t("Region.Oceania")}
                      </Select.Option>
                      <Select.Option value="AF">
                        {t("Region.Africa")}
                      </Select.Option>
                    </Select>
                  </FormItem>
                )}
              />
            </Flex>
            <Flex justify="space-between" gap={24}>
              <FormItem
                required
                control={control}
                name="amount"
                label={t("createMatch.numberOfVacancies")}
                labelCol={{ style: { fontWeight: "bold" } }}
                style={{ marginTop: 2, width: "100%" }}
              >
                <InputNumber
                  placeholder={t("createMatch.placeHolder.amount")}
                  style={{ width: "100%" }}
                />
              </FormItem>
              <FormItem
                required
                control={control}
                name="value"
                label={t("createMatch.value")}
                labelCol={{ style: { fontWeight: "bold" } }}
                style={{ marginTop: 2, width: "100%" }}
              >
                <InputNumber
                  placeholder={"0.00"}
                  style={{ width: "100%" }}
                  formatter={(value) => {
                    let strValue = String(value)
                    while (strValue.length < 3) {
                      strValue = "0" + strValue
                    }
                    return strValue.replace(/(\d)(?=\d{2}$)/, "$1,")
                  }}
                  parser={(value) =>
                    value?.replace(/\$\s?|(,*)/g, "") as unknown as number
                  }
                  addonBefore={
                    <FormItem
                      control={control}
                      name="currency"
                      style={{ margin: 0, padding: 0, width: "100%" }}
                    >
                      <Select
                        defaultValue={"brl"}
                        style={{ padding: 0, margin: 0 }}
                      >
                        <Select.Option value="brl">BRL</Select.Option>
                        <Select.Option value="usd">USD</Select.Option>
                      </Select>
                    </FormItem>
                  }
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 2,
                  }}
                >
                  <Typography.Text
                    style={{
                      fontSize: 12,
                      color: "#8C8C8C",
                      marginTop: 2,
                    }}
                  >
                    {t("createMatch.serviceTax", {
                      value: 20,
                    })}
                  </Typography.Text>
                  <Typography.Text
                    style={{
                      fontSize: 12,
                      color: "#8C8C8C",
                      marginTop: 2,
                    }}
                  >
                    {t("createMatch.youWillGet", {
                      value: ((watch("value") || 0) * 0.8 * 0.01).toFixed(2),
                    })}
                  </Typography.Text>
                </div>
              </FormItem>
            </Flex>
            <Flex justify={"right"}>
              <Button
                disabled={isSubmitting}
                loading={isSubmitting}
                htmlType="submit"
                style={{
                  borderRadius: 2,
                  fontSize: 14,
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  backgroundColor: isValid ? "#49AA19" : "#3F3F40",
                  color: isValid ? "#000" : "#7B7B7B",
                }}
              >
                {t("createMatch.confirm")}
              </Button>
            </Flex>
          </Form>
        </>
      </Modal>
    </>
  )
}
