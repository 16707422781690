import { Divider, Layout } from "antd"
import { Link } from "react-router-dom"
import {
  InstagramIcon,
  TwitchICon,
  TikTokIcon,
  YoutubeIcon,
  LinkedinIcon,
} from "../../Icons"
import { useTranslation } from "react-i18next"
import { env } from "../../../utils/env"
import { ClassValue } from "clsx"
import { DiscordIcon } from "../../Icons/DiscordIcon"

const { Footer: AntFooter } = Layout

type Props = {
  paymentMethods?: boolean
  ClassName?: ClassValue
}
export default function MethodsFooter({ paymentMethods, ClassName }: Props) {
  const { t } = useTranslation()

  return (
    <AntFooter
      className={
        "bg-tertiary p-0 text-center font-bold uppercase text-white " +
        ClassName
      }
    >
      <div className="mx-4 pt-8 md:mx-20">
        {paymentMethods && (
          <>
            <div className="flex flex-col justify-between md:flex-row">
              <div className="flex flex-col items-start gap-y-6">
                <p className="m-0 text-xl font-bold text-white">DUOTOPLAY</p>
                <Link to={"/terms"} className="text-[#FF7A00]">
                  {t("TermsOfService.title")}
                </Link>
                <Link to={"/faq"} className="text-[#FF7A00]">
                  {t("Faq.title")}
                </Link>
              </div>
              <div className="mt-3 flex flex-col items-center">
                <span className="uppercase text-neutral">
                  {t("SocialMedia")}
                </span>
                <div className="mt-3 flex justify-center gap-x-2">
                  <Link to={env.DUO_INSTAGRAM} target="_blank">
                    <InstagramIcon />
                  </Link>
                  <Link to={env.DUO_TIKTOK} target="_blank">
                    <TikTokIcon />
                  </Link>
                  <Link to={env.DUO_YOUTUBE} target="_blank">
                    <YoutubeIcon />
                  </Link>
                  <Link to={env.DUO_TWITCH} target="_blank">
                    <TwitchICon />
                  </Link>
                  <Link to={env.DUO_LINKEDIN} target="_blank">
                    <LinkedinIcon />
                  </Link>
                  <Link to={env.DUO_DISCORD} target="_blank">
                    <DiscordIcon />
                  </Link>
                </div>
              </div>
              <div />
            </div>
            <Divider className="my-8" />
          </>
        )}

        <div className="flex justify-around pb-4">
          <div className="flex items-start gap-x-12 uppercase text-[#3F3F40]">
            <p className="m-0">{t("BetaVersion")}</p>
            {!paymentMethods && (
              <div className="flex gap-x-4 text-xs">
                <Link to={"/terms"} className="text-[#FF7A00]">
                  {t("TermsOfService.title")}
                </Link>
                <Link to={"/faq"} className="text-[#FF7A00]">
                  {t("Faq.title")}
                </Link>
              </div>
            )}
          </div>
          <span className="uppercase text-[#3F3F40]">
            2024, dUOTOPLAY. {t("RightsReserved")}.
          </span>
        </div>
      </div>
    </AntFooter>
  )
}
