import ReactMarkdown from "react-markdown"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { Spin } from "antd"
import { Helmet } from "react-helmet"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
export default function FAQ() {
  const location = useLocation()
  const { t } = useTranslation()
  const { data, isLoading } = useQuery({
    queryKey: ["FAQ"],
    queryFn: async () => {
      const { data } = await axios.get<string>(
        `${process.env.PUBLIC_URL}/FAQ.md`,
      )
      return data
    },
  })

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Helmet.faq.title")}</title>
        <meta
          name="description"
          content={t("Helmet.faq.description")}
        />
        <link rel="canonical" href={origin + location.pathname} />
      </Helmet>
      <div className="mx-auto flex w-[89.25rem] flex-col">
        {isLoading ? (
          <div className="flex flex-grow items-center justify-center">
            <Spin size="large" />
          </div>
        ) : (
          <ReactMarkdown children={data} />
        )}
      </div>
    </>
  )
}
